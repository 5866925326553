import React from "react";
import { DataContext } from '../../../Containers/DataProvider';

class CategoriesDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        }
    }

    componentDidMount() {
        if (this.context.categories) {
            this.setState({
                categories: JSON.parse(JSON.stringify(this.context.categories)),
            }, () => {
                if (this.props.activeCategory) {
                    const activeCategory = this.state.categories.draft.category.find(category => category.id === this.props.activeCategory);
                    
                    if (activeCategory) {
                        this.setState({
                            selectedCategory: activeCategory
                        });
                    }
                }
            })
        }
    }

    toggleDropdown() {
        this.setState({
            isOpened: !this.state.isOpened
        });
    }

    selectCategory(category) {
        this.setState({
            selectedCategory: category,
            isOpened: !this.state.isOpened
        });
        this.props.createNewQuestion('categoryId', category.id, this.props.newQuestionId);
    }


    render() {
        return (
            this.state && this.state.categories ?
                <>
                    {
                        this.props.label ?
                        <label className="input-label">{this.props.label}</label>
                        : ""
                    }
                    <div className={`input custom-select ${this.state.isOpened ? 'opened' : ''}`}>
                        <div className="click-area" onClick={() => {this.toggleDropdown()}}></div>
                        <span className="material-icons icon">
                            expand_more
                        </span>
                        <span className="select-placeholder">
                            {this.state.selectedCategory ? this.state.selectedCategory.name : 'Select a category'}
                        </span>
                        <div className="options">
                            {this.state.categories.draft.category.map((category, index) =>
                                <div className="option" key={index} onClick={() => this.selectCategory(category) }>
                                    {category.name}
                                </div>
                            )}
                        </div>
                    </div>
                </>
                : ''
        );
    }

}

CategoriesDropdown.contextType = DataContext;
export default CategoriesDropdown;