import React from "react";

class RadioButton extends React.Component {

    render() {
        return (
            <>
                <div className={`radio-button-wrapper ${this.props.checked === true ? "checked" : ""}`}
                    onClick={() => {
                        this.props.updatePayload(this.props.keyToUpdate, this.props.valueToUpdate)}
                    }
                >
                    <div className='radio'></div>
                    <p className='label'>{this.props.label}</p>
                </div>
            </>
        );
    }

}

export default RadioButton;