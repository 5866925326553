import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import ColorField from '../../Dashboard/Components/ColorField';
import DOMPurify from 'dompurify';

class IconSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icons: [
                {
                    'name': 'icon-type-1',
                    'svg': '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path id="Path_3154" data-name="Path 3154" d="M13.25,22h2.5V19.5h-2.5ZM14.5,2A12.5,12.5,0,1,0,27,14.5,12.5,12.5,0,0,0,14.5,2Zm0,22.5a10,10,0,1,1,10-10A10.013,10.013,0,0,1,14.5,24.5ZM14.5,7a5,5,0,0,0-5,5H12a2.5,2.5,0,1,1,5,0c0,2.5-3.75,2.187-3.75,6.25h2.5c0-2.813,3.75-3.125,3.75-6.25A5,5,0,0,0,14.5,7Z" transform="translate(-2 -2)" fill="#404a65"/></svg>'
                },
                {
                    'name': 'icon-type-2',
                    'svg': '<svg xmlns="http://www.w3.org/2000/svg" width="37.25" height="44.25" viewBox="0 0 37.25 44.25"><path id="contact_support_FILL0_wght700_GRAD200_opsz48" d="M24.1,46.05l-.2-6.5-2.2-.05A18.133,18.133,0,0,1,9.4,33.425,18.41,18.41,0,0,1,4.4,20.6,18.061,18.061,0,0,1,9.95,7.325,18.359,18.359,0,0,1,23.4,1.8a17.805,17.805,0,0,1,7.15,1.45,18.083,18.083,0,0,1,5.8,3.95,18.47,18.47,0,0,1,3.875,5.95A19.322,19.322,0,0,1,41.65,20.6a21.663,21.663,0,0,1-1.35,7.525,29.63,29.63,0,0,1-3.65,6.925,35.363,35.363,0,0,1-5.5,6.075A38.376,38.376,0,0,1,24.1,46.05Zm4.15-8a24.223,24.223,0,0,0,6.4-8.125,21.185,21.185,0,0,0,2.3-9.325A13.913,13.913,0,0,0,33.1,10.425,13.08,13.08,0,0,0,23.35,6.5,13.849,13.849,0,0,0,13.2,10.625,13.664,13.664,0,0,0,9.05,20.7a13.789,13.789,0,0,0,4.125,10.1A13.664,13.664,0,0,0,23.25,34.95h5ZM23.4,32.6a2.391,2.391,0,0,0,2.35-2.35,2.285,2.285,0,0,0-.7-1.625A2.22,2.22,0,0,0,23.4,27.9a2.3,2.3,0,0,0-1.675.725A2.247,2.247,0,0,0,21,30.25a2.22,2.22,0,0,0,.725,1.65A2.34,2.34,0,0,0,23.4,32.6Zm-1.85-7.1h3.4a4.921,4.921,0,0,1,.45-2.175,7.8,7.8,0,0,1,1.65-2.075A11.88,11.88,0,0,0,29,18.725a5.388,5.388,0,0,0,.6-2.575,5.593,5.593,0,0,0-1.525-4.1A5.935,5.935,0,0,0,23.65,10.5a6.826,6.826,0,0,0-4.1,1.3,7.859,7.859,0,0,0-2.7,3.55L20,16.6a4.375,4.375,0,0,1,1.475-2,3.3,3.3,0,0,1,1.925-.65,3.2,3.2,0,0,1,2,.575,1.928,1.928,0,0,1,.75,1.625,3.354,3.354,0,0,1-.55,1.75,15.377,15.377,0,0,1-1.8,2.25,7.558,7.558,0,0,0-1.85,2.425,8.37,8.37,0,0,0-.4,2.925ZM23,22.25Z" transform="translate(-4.4 -1.8)"/></svg>'
                },
                {
                    'name': 'icon-type-3',
                    'svg': '<svg xmlns="http://www.w3.org/2000/svg" width="34.445" height="38.75" viewBox="0 0 34.445 38.75"><path id="psychology_alt_FILL0_wght600_GRAD200_opsz48" d="M25.5,28.6a1.708,1.708,0,0,0,1.175-.475A1.632,1.632,0,0,0,26.7,25.75a1.637,1.637,0,0,0-1.2-.5,1.623,1.623,0,0,0-1.65,1.65,1.681,1.681,0,0,0,.475,1.2A1.556,1.556,0,0,0,25.5,28.6Zm-1.15-4.75H26.7a5.639,5.639,0,0,1,.1-1.25,2.193,2.193,0,0,1,.7-.95q.1-.1.4-.45t.5-.6A5.839,5.839,0,0,0,29.55,19a3.9,3.9,0,0,0,.3-1.55,3.687,3.687,0,0,0-1.15-2.825,4.286,4.286,0,0,0-3.05-1.075,4.538,4.538,0,0,0-2.8.9A5.157,5.157,0,0,0,21.1,16.8l2.15.9a3.179,3.179,0,0,1,.9-1.425,2.06,2.06,0,0,1,1.4-.525,1.877,1.877,0,0,1,1.425.55,1.82,1.82,0,0,1,.525,1.3,1.887,1.887,0,0,1-.25.975,5.63,5.63,0,0,1-.65.875q-.5.45-.925.825a5.205,5.205,0,0,0-.725.775,2.753,2.753,0,0,0-.5,1.25,11.011,11.011,0,0,0-.1,1.55ZM17.65,43.6V36.9h-2.7a3.953,3.953,0,0,1-2.925-1.25,4.018,4.018,0,0,1-1.225-2.9v-4.7H9.3a2.239,2.239,0,0,1-2.125-1.3A2.647,2.647,0,0,1,7.25,24.1l3.55-6.4a16.834,16.834,0,0,1,5.625-9.35,14.671,14.671,0,0,1,9.625-3.5A14.688,14.688,0,0,1,36.825,9.325,14.63,14.63,0,0,1,41.3,20.05a13.815,13.815,0,0,1-2.35,7.925A16.131,16.131,0,0,1,32.5,33.45V43.6Zm4.15-4.15h6.5v-8.6l2.05-1a12.551,12.551,0,0,0,4.825-3.925A9.481,9.481,0,0,0,37.1,20.1a10.638,10.638,0,0,0-3.25-7.8A10.58,10.58,0,0,0,26.1,9.05a10.685,10.685,0,0,0-7.65,3,9.487,9.487,0,0,0-3.3,6.5L12,24.2h2.95v8.55H21.8ZM24.55,24.2Z" transform="translate(-6.855 -4.85)"/></svg>'
                },
                {
                    'name': 'icon-type-4',
                    'svg': '<svg xmlns="http://www.w3.org/2000/svg" width="21.71" height="21.771" viewBox="0 0 21.71 21.771"><path id="forum_FILL1_wght600_GRAD0_opsz48_1_" data-name="forum_FILL1_wght600_GRAD0_opsz48 (1)" d="M61.516-888.4a1.21,1.21,0,0,1-.892-.425,1.292,1.292,0,0,1-.4-.9v-2.767h13.1v-8.987h2.819a1.182,1.182,0,0,1,.87.425,1.323,1.323,0,0,1,.4.921v15.9L73.235-888.4Zm-5.822-1.356v-14.926a1.3,1.3,0,0,1,.4-.9,1.2,1.2,0,0,1,.884-.425H70.487a1.221,1.221,0,0,1,.9.412,1.28,1.28,0,0,1,.4.908v9.339a1.287,1.287,0,0,1-.4.9,1.214,1.214,0,0,1-.9.418H59.961Z" transform="translate(-55.694 906.001)" fill="#fff"/></svg>'
                }
            ]
        }
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            const selectedIcon = this.state.icons.find(icon => icon.name === this.context.account.widgetSettings.draft.iconSettings.iconType)
            this.setState({
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
                selectedIcon: selectedIcon,
            }, () => {
                this.props.updatePayloadData('iconSettings', this.state.widgetSettings.draft.iconSettings);
            })
        }
    }

    updatePayload = (key, value) => {

        const cleanString = DOMPurify.sanitize(value);
        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.iconSettings[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('iconSettings', this.state.widgetSettings.draft.iconSettings);

        // SaveButton.autoSave(
        //     this.context,
        //     {variant: 'draft', property:'widgetSettings'},
        //     this.props.payload,
        //     "accounts",
        //     this.context.accountsRefId
        // );
    }

    updateCurrentIcon = (i) => {

        const selectedIcon = this.state.icons.find(icon => icon.name === i)
        this.setState({
            selectedIcon: selectedIcon,
        });

    }

    render() {
        return (
            this.state.widgetSettings ?
            <>
                <div className='faq-form'>
                    <div className='two-columns'>
                        <ColorField 
                            wrapperCssClasses="w50"
                            color={this.state.widgetSettings.draft.iconSettings.backgroundColor || ''}
                            labelText="Background color"
                            // infoTooltip="Test tooltip text"
                            UpdateColor={this.updatePayload}
                            propToUpdate='backgroundColor'
                        />
                        <ColorField 
                            wrapperCssClasses="w50"
                            color={this.state.widgetSettings.draft.iconSettings.iconColor || ''}
                            labelText="Text color"
                            // infoTooltip="Test tooltip text"
                            UpdateColor={this.updatePayload}
                            propToUpdate='iconColor'
                        />
                    </div>
                    <div className='two-columns widget-icon-select'>
                        <div className="icon-preview form-input" dangerouslySetInnerHTML={{__html: this.state.selectedIcon.svg}}>
                        </div>
                        <div className='fieldset w50'>
                        <label>Icon type</label>
                            <select className="form-input"
                            defaultValue={this.state.widgetSettings.draft.iconSettings.iconType || ''}
                            onChange={
                                (e) => {this.updatePayload('iconType', e.currentTarget.value); this.updateCurrentIcon(e.currentTarget.value);}
                            }>
                            {this.state.icons.map((icon, index) =>
                                <option key={index}>
                                    {icon.name}
                                </option>
                            )}
                            </select>
                        </div>
                    </div>

                    <div className="one-column">
                        <div className="fieldset">
                            <label>Widget tooltip message</label>
                            <input type="text" className="input" onChange={(e) => this.updatePayload('widgetTooltip', e.currentTarget.value)} defaultValue={this.state.widgetSettings.draft.iconSettings.widgetTooltip || ''} 
                            maxLength={100}
                            />
                        </div>
                    </div>
                </div>
            </>
            : ''
        );
    }

}

IconSettings.contextType = DataContext;
export default IconSettings;