import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import RichText from '../../Dashboard/Components/RichText';
import CategoriesDropdown from "./CategoriesDropdown";
import Modal from '../../../hoc/Modal';
import SaveButton from '../../Dashboard/Components/SaveButton';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import DeleteQuestion from './DeleteQuestion';
import Notification from '../../Dashboard/Components/Alert';
import DOMPurify from 'dompurify';

class Questions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.newQuestion = {
            question: '',
            answer: '',
            categoryId: '',
            id: (Math.random() + 1).toString(36).substring(2),
        };
    }

    componentDidMount() {
        if (this.context.questions) {
            this.setState({
                questions: JSON.parse(JSON.stringify(this.context.questions)),
            }, () => {
                this.props.updatePayloadData('questions', this.state.questions);
            })
        }
    }

    createNewQuestion = (key, value, id) => {

        const cleanString = DOMPurify.sanitize(value);
        this.newQuestion[key] = cleanString;

        const newState = Object.assign({}, this.state);
        const questions = newState.questions.draft.question;

        const questionIndex = questions.findIndex(q => q.id === id);

        if (questionIndex > -1) {

            newState.questions.draft.question[questionIndex][key] = cleanString;

        } else {

            newState.questions.draft.question.push(this.newQuestion);

        }

        this.props.updatePayloadData('questions', newState.questions);

    }

    buildQuestionsHTML = () => {

        const DeleteModal = Modal('<span class="material-icons delete-category">delete</span>', DeleteQuestion, 'size-m');
        const EditModal = Modal('<span class="material-icons delete-category">edit</span>', this.AddEditQuestionHTML, 'size-xl');

        const questionsHtml = (
            <>
                {this.state.questions.draft.question.map((question, index) =>
                    <div className="faq-form questions-form" key={question.id}>
                        <div className="">
                            <div className="fieldset">
                                <div className="input">
                                    <span>{question.question || ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <EditModal question={question} addNewEntry={false} />
                            <DeleteModal category={question} index={index} question={question} deleteQuestion={this.deleteQuestion} isLoading={this.state.deleteButtonIsLoading} />
                        </div>
                    </div>
                )}
            </>
        );

        return questionsHtml;

    }

    AddEditQuestionHTML = (props) => {

        let entry;
        if (props.addNewEntry) {
            entry = this.newQuestion;
        } else {
            entry = props.question;
        }

        const QuestionHtml = (
            <div className="questions-editor-body">
                <div className="faq-form no-margin">
                    <div className="two-columns">
                        <div className="fieldset">
                            <label>Question</label>
                            <input type="text" className="input" onChange={(e) => this.createNewQuestion('question', e.currentTarget.value, entry.id)} defaultValue={props.question.question || ''} />
                        </div>
                        <div className="fieldset">
                            <CategoriesDropdown label='Category' createNewQuestion={this.createNewQuestion} newQuestionId={entry.id} activeCategory={entry.categoryId} />
                        </div>
                    </div>
                    <RichText label='Answer' updateData={this.createNewQuestion} newQuestionId={entry.id} answer={entry.answer} />
                </div>
                <div className="buttons">
                    <div className="secondary-button blue-button medium-button" onClick={() => { props.closeModal();this.newQuestion={}}}>
                        <span>Cancel</span>
                    </div>

                    <SaveButton 
                        payload={this.props.payload}
                        buttonText='Save'
                        cssClasses="primary-button blue-button medium-button"
                        dataToProcess={{variant: 'draft', property:'questions'}} 
                        db="content"
                        docRef={this.context.contentRefId} />

                </div>
            </div>
        );

        return QuestionHtml;

    }


    DeleteQuestionHTML = (props) => {

        let isLoading = false;
        const QuestionHtml = (
            <>
                <span className="material-icons text-blue modal-icon">delete</span>
                <h2 className="title">Deleting "<span className="text-blue">{props.question.question}</span>" question?</h2>
                <p className="subtitle"></p>
                <div className="buttons">
                    <div className="secondary-button blue-button medium-button" onClick={() => { props.closeModal() }}>
                        <span>Cancel</span>
                    </div>
                    <div className={`primary-button blue-button medium-button ${isLoading === true ? "loading disabled" : ""}`} onClick={() => { props.deleteQuestion(props.index); isLoading = true;}}>
                        <span>Delete</span>
                    </div>

                </div>
            </>
        );

        return QuestionHtml;

    }

    deleteQuestion = (index) => {

        const newState = Object.assign({}, this.state);
        if (!newState) {
            return
        }
        newState.questions.draft.question.splice(index, 1);
        newState.questions.draft.lastUpdate = new Date().toLocaleString();
        this.props.updatePayloadData('questions', newState.questions);

        const currentDocRef = doc(db, 'content', this.context.contentRefId);
        updateDoc(currentDocRef, newState).then(() => {

            document.body.classList.remove('modal-open', 'overflow-hidden');
            this.context.getContentTable();

            Notification.updateNotification({type: 'success',title: 'Success',message: 'Your data have been updated'}); 

        });

    }


    render() {

        const AddNewModal = Modal('<div class="secondary-button blue-button medium-button "><span>Add new  question</span></div>', this.AddEditQuestionHTML, 'size-xl');

        return (
            this.state && this.state.questions ?
                <>
                    {
                        this.state.questions.draft.question.length > 0
                            ?
                            this.buildQuestionsHTML()
                            :
                            <div className="no-item-added">
                                <p>
                                    <span className="material-icons text-blue">add_circle</span>
                                    <span>No questions added yet. Add your first question.</span>
                                </p>
                            </div>
                    }
                    {
                        this.state.questions.draft.question.length < this.context.activeSubscription.questions
                        ?
                        <div className="text-right mt-30">
                            <AddNewModal question={this.newQuestion} addNewEntry={true} />
                        </div>
                        :
                        <div className="limit-reached-info">
                            <span className="material-icons">warning</span>
                            <p>You've reached the limit for categories. New plans will be added soon. </p>
                        </div>
                    }

                </>
            : ''
        );
    }

}

Questions.contextType = DataContext;
export default Questions;