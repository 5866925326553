import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Auth from './Auth/Auth';
import DashboardWrapper from './Dashboard/DashboardWrapper';
import { getParams, saveParams } from "../utils/util";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';

class Routes extends React.Component {
  constructor() {
    super();
    const extraParams = getParams();
    saveParams(extraParams);

    this.state = {
      routesLoading: true,
      dashboardRoutes: [
        '/dashboard/widget-settings/home-screen',
        '/dashboard/widget-settings/visual',
        '/dashboard/widget-settings/chat',
        '/dashboard/categories',
        '/dashboard/questions',
        '/dashboard/install',
        '/dashboard/conversations',
        '/dashboard/conversations', // Duplicate entry
      ],
      accountCreated: false, // Flag to track whether the account is created
    };
  }

  componentDidMount() {
    const auth = getAuth();
    const maxRetries = 3; // Number of times to retry the query
  
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const accounts = collection(db, "accounts");
        const accountsQuery = query(accounts, where("users", "array-contains", user.uid));
  
        let retryCount = 0;
        const component = this; // Capture 'this' in a variable
  
        const performQueryWithRetry = function() { // Use a regular function declaration
          getDocs(accountsQuery)
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                if (retryCount < maxRetries) {
                  // Query returned no documents, retry after a delay
                  retryCount++;
                  setTimeout(performQueryWithRetry, 1000); // Retry after 1 second
                } else {
                  // Max retries reached, create a new account
                  return addDoc(collection(db, 'accounts'), {
                    users: [user.uid],
                    usersDetails: [
                      {
                        'id': user.uid,
                        'email': user.email,
                        'role': 'owner'
                      }
                    ],
                    domain: '',
                  }).then(() => {
                    // Access 'accountCreated' using the captured 'component'
                    component.setState({
                      auth: true,
                      routesLoading: false,
                    });
                  });
                }
              } else {
                component.setState({
                  auth: true,
                  routesLoading: false,
                });
                return Promise.resolve();
              }
            })
            .then(() => {
              // Check if the account is created
              if (!component.state.accountCreated) {
                component.setState({ accountCreated: true });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        };

        console.log(window.location.pathname);

        this.setState({
          dashboardRouteExists: this.state.dashboardRoutes.find(route => window.location.pathname.startsWith(route))
        })

        performQueryWithRetry(); // Start the initial query
      } else {
        this.setState({
          auth: false,
          dashboardRouteExists: false,
          routesLoading: false,
        });
      }
    });
  }
    

  render() {
    return (
      <>
        {this.state.routesLoading ?
          <div className='loader-overlay'>
            <div className='loader'></div>
          </div>
          :
          <Router>
            {this.state.auth === true ?
              this.state.dashboardRouteExists ?
                <Redirect to={window.location.pathname} />
                :
                <Redirect to='/dashboard/widget-settings/home-screen' />
              :
              <Redirect to='/auth/login' />
            }
            <Route path="/auth" component={Auth} />
            <Route path="/dashboard/" component={DashboardWrapper} />
          </Router>
        }
      </>
    );
  }
}

export default Routes;
