import React from "react";
import { DataContext } from '../../Containers/DataProvider';
import SaveButton from '../Dashboard/Components/SaveButton';
import DashboardTabs from '../Dashboard/Components/DashboardTabs';
import { Switch, Route } from "react-router-dom";
import Visual from './Components/Visual';
import HomeScreen from './Components/HomeScreen';
import Chat from './Components/Chat';

class WidgetSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    updatePayloadData = (key, value) => {
        this.payload.widgetSettings.draft[key] = value;
    }

    render() {

        this.payload = this.context.account;

        return (
            <div>
                <div className='header-actions'>
                    <div>
                        {/* <p>{this.context.account.widgetSettings && this.context.account.widgetSettings.live.lastUpdate ? `Last publish on ${this.context.account.widgetSettings.live.lastUpdate}` : 'Not published yet'}, {this.context.account.widgetSettings && this.context.account.widgetSettings.draft.lastUpdate ? `Last saved on ${this.context.account.widgetSettings.draft.lastUpdate}` : 'Not saved yet'}</p> */}
                        <div className='flex-row flex-align-end'>
                            {/* {this.context.account.widgetSettings.draft.lastUpdate.seconds > this.   context.account.widgetSettings.live.lastUpdate.seconds || 0 ?
                                <InfoTooltip infoText='You have unpublished changes.' icon='warning' cssClasses='arrow-bottom header-info text-yellow'/>
                                :
                                // <InfoTooltip infoText={lastUpdateInfoTooltip} icon='info' cssClasses='arrow-bottom header-info'/>
                                ''
                                } */}
                            {/* <a href={this.state.previewUrl} className='link' target='_blank' rel='noopener noreferrer'>Live preview <i className='icon-new-tab'></i></a> */}
                            
                            <SaveButton endpoint='#' payload={this.payload} buttonText='Save changes <span class="material-icons icon">file_download</span>' cssClasses='link' dataToProcess={{ variant: 'draft', property: 'widgetSettings' }} triggerAutoSave="true" db="accounts" docRef={this.context.accountsRefId} />
                            <SaveButton endpoint='#' payload={this.payload} buttonText='Publish changes' cssClasses={`${this.context.user && this.context.user.emailVerified === false ? "disabled" : ""} primary-button blue-button medium-button`} disabled={`${this.context.user && this.context.user.emailVerified === false ? "true" : "false"}`} dataToProcess={{ variant: 'live', property: 'widgetSettings' }} db="accounts" docRef={this.context.accountsRefId} />
                        </div>
                    </div>
                </div>
                <DashboardTabs links={[
                    {
                        url: '/dashboard/widget-settings/home-screen',
                        title: 'Home screen'
                    },
                    {
                        url: '/dashboard/widget-settings/visual',
                        title: 'Visual'
                    },
                    {
                        url: '/dashboard/widget-settings/chat',
                        title: 'Chat'
                    }
                ]} />
                {
                    <Switch>
                        <Route path="/dashboard/widget-settings/home-screen">
                            <HomeScreen updatePayloadData={this.updatePayloadData} payload={this.payload} />
                        </Route>
                        <Route path="/dashboard/widget-settings/visual">
                            <Visual updatePayloadData={this.updatePayloadData} payload={this.payload} />
                        </Route>
                        <Route path="/dashboard/widget-settings/chat">
                            <Chat updatePayloadData={this.updatePayloadData} payload={this.payload} />
                        </Route>
                    </Switch>
                }

            </div>
        );
    }
}

WidgetSettings.contextType = DataContext;
export default WidgetSettings;
