import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Forgot from "./Forgot";
// import Confirm from "./Confirm";
import Logo from "../../assets/images/logo-white.svg";
// import ResetPassword from "./ResetPassword";

const Auth = () => {
    let { path } = useRouteMatch();
    return (
        <div className="auth">
            <img src={Logo} alt="Answers Automate" className="logo" />
            <div className="form">
                <Switch>
                    <Route path={`${path}/login`}>
                        <Login />
                    </Route>
                    <Route path={`${path}/register`}>
                        <Register />
                    </Route>
                    <Route path={`${path}/forgot`}>
                        <Forgot />
                    </Route>
                    {/* <Route path={`${path}/confirm`}>
                        <Confirm />
                    </Route>
                    <Route path={`${path}/reset-password`}>
                        <ResetPassword />
                    </Route> */}
                </Switch>
            </div>
            <p className="copyrights">© {new Date().getFullYear()} Answers Automate</p>
        </div>

    );
};

export default Auth;
