import React from "react";
import { DataContext } from '../../../Containers/DataProvider';

class ConversationDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reversedMessages: null,
        }
    }

    componentDidMount() {
        this.reverseMessages();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.messages !== this.props.messages) {
            this.reverseMessages();
        }
    }

    reverseMessages() {
        const { messages } = this.props;

        if (!messages) {
            return;
        }

        const reversedMessages = {};

        Object.keys(messages)
            .filter((key) => key !== 'timestamp' && key !== 'read' && key !== 'userDetails')
            .map((key) => ({
                date: new Date(key.split('/').reverse().join('/')),
                value: key,
            }))
            .sort((a, b) => a.date - b.date) // Corrected comparison here
            .forEach((entry) => {
                reversedMessages[entry.value] = messages[entry.value];
            });

        this.setState({ reversedMessages });

    }

    render() {

        const { reversedMessages } = this.state;

        return (
            this.state.reversedMessages &&
            <div className="conversation">
                <div className="chat">
                    {Object.keys(reversedMessages).map((date) => (
                        <React.Fragment key={date}>
                            <div className="session-date">{date}</div>
                            {reversedMessages[date].map((message, index) => (
                                <div
                                    className={`message-wrapper ${message.received ? 'sent' : ''} ${message.sent ? 'received' : ''}`}
                                    key={index}
                                >
                                    <div className="message" dangerouslySetInnerHTML={{ __html: message.completion }}></div>
                                    {/* <span className="message-timestamp">
                                        {
                                            message.sent && message?.userDetails?.email
                                            ? message?.userDetails?.email : 'Visitor'
                                        }

                                        {
                                        message.received && this.context.account.widgetSettings.live.chatSection.assistantName
                                        }
                                    </span> */}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }


}

ConversationDisplay.contextType = DataContext;
export default ConversationDisplay;

