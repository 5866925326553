import React from "react";

class InfoTooltip extends React.Component {
    render() {
        return (
            <div className={`info-tooltip-wrapper ${this.props.cssClasses}`} >
                <span className='material-icons'>
                    {this.props.icon ? this.props.icon : 'info'}
                </span>
                <div className="tooltip">
                    {
                        this.props.infoText ? this.props.infoText : ''
                    }
                </div>
            </div>
        );
    }

}

export default InfoTooltip;