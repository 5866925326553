import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import WithCard from '../../../hoc/WithCard';
import HeaderSettings from './HeaderSettings';
import IconSettings from './IconSettings';
import WidgetPosition from './WidgetPosition';
import TextSettings from './TextSettings';
import BodySettings from './BodySettings';

class Visual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    render() {

        const HeaderSettingsCard = WithCard(HeaderSettings);
        const IconSettingsCard = WithCard(IconSettings);
        const WidgetPositionCard = WithCard(WidgetPosition);
        const TextSettingsCard = WithCard(TextSettings);
        const BodySettingsCard = WithCard(BodySettings);
        this.payload = this.props.payload;

        return (
            <div>
                <div className="mt-80 mb-80">
                    <div className='large-container'>
                        <HeaderSettingsCard title='Header settings' isOpen='true' updatePayloadData={this.props.updatePayloadData} payload={this.payload} />
                        <IconSettingsCard title='Widget icon' isOpen='true' updatePayloadData={this.props.updatePayloadData} payload={this.payload} />
                        <WidgetPositionCard title='Widget position' isOpen='true' updatePayloadData={this.props.updatePayloadData} payload={this.payload} />
                        <TextSettingsCard title='Text settings' isOpen='true' updatePayloadData={this.props.updatePayloadData} payload={this.payload} />
                        <BodySettingsCard title='Body settings' isOpen='true' updatePayloadData={this.props.updatePayloadData} payload={this.payload} />
                    </div>
                </div>
            </div>
        );
    }
}

Visual.contextType = DataContext;
export default Visual;
