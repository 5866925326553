import React, { Component } from 'react';

export default function Modal(ButtonHtml, WrappedComponent, modalSize) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isOpen: false,
            }
        }

        openModal = () => {

            this.setState({
                isOpen: !this.state.isOpen,
            }, () => {
                document.body.classList.add('modal-open', 'overflow-hidden');
            });

        }

        closeModal = () => {

            this.setState({
                isOpen: !this.state.isOpen,
            }, () => {
                document.body.classList.remove('modal-open', 'overflow-hidden');
            });

        }

        onKeyDown = event => {
            if (event.keyCode === 27 && this.state.isOpen === true) {
                this.closeModal();
            }
        };

        render() {
            return (
                <>
                    <div onClick={() => this.openModal()}
                        dangerouslySetInnerHTML={{ __html: ButtonHtml }} className="inline-block"/>
                    <div onKeyDown={this.onKeyDown} tabIndex='0' className='no-outline'>
                        {this.state.isOpen ?
                            <>
                                <div className='modal'
                                    // onClick={(event) => {
                                    //     if (event.target.className !== 'modal-card') 
                                    //     { event.stopPropagation(); this.closeModal()} }}
                                        >
                                    <div className={`modal-card ${modalSize}`}>
                                        <span className='hover-effect material-icons close-button' onClick={() => this.closeModal()}>close</span>
                                        {
                                            WrappedComponent ?
                                            <WrappedComponent {...this.props} closeModal={this.closeModal} />
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className='modal-overlay' />
                            </>
                            : ''}
                    </div>
                </>
            );
        }
    }
}
