import React from "react";
import { Editor, EditorState, RichUtils, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";


class RichText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(props.answer || '')
                )
            ),

        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.setState({ 
                editorState,
                editorContentHtml: stateToHTML(editorState.getCurrentContent())
            }, () => {
                this.props.updateData('answer', this.state.editorContentHtml, this.props.newQuestionId);
            });
            

        }

        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    }

    _handleKeyCommand(command) {
        const { editorState } = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {
        const { editorState } = this.state;

        let className = 'rich-text-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <>
                {
                    this.props.label ?
                        <label className="input-label">{this.props.label}</label>
                        : ''
                }
                <div className="rich-text-editor-wrapper">
                    <div className="rich-text-action-row">
                        <BlockStyleControls
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                        />
                        <InlineStyleControls
                            editorState={editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                    </div>
                    <div className={className} onClick={this.focus}>
                        <Editor
                            blockStyleFn={getBlockStyle}
                            customStyleMap={styleMap}
                            editorState={editorState}
                            handleKeyCommand={this.handleKeyCommand}
                            onChange={this.onChange}
                            onTab={this.onTab}
                            placeholder=""
                            ref="editor"
                            spellCheck={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}


const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'editor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'rich-text-action';
        if (this.props.active) {
            className += ' rich-text-action-active';
        }

        return (
            <span className={`${className} material-symbols-outlined`} onMouseDown={this.onToggle}>
                {this.props.icon}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one', icon: 'format_h1' },
    { label: 'H2', style: 'header-two', icon: 'format_h2' },
    { label: 'H3', style: 'header-three', icon: 'format_h3' },
    { label: 'H4', style: 'header-four', icon: 'format_h4' },
    { label: 'H5', style: 'header-five', icon: 'format_h5' },
    { label: 'H6', style: 'header-six', icon: 'format_h6' },
    { label: 'Blockquote', style: 'blockquote', icon: 'format_quote' },
    { label: 'UL', style: 'unordered-list-item', icon: 'format_list_bulleted' },
    { label: 'OL', style: 'ordered-list-item', icon: 'format_list_numbered' },
    { label: 'Code Block', style: 'code-block', icon: 'code' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    icon={type.icon}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD', icon: 'format_bold' },
    { label: 'Italic', style: 'ITALIC', icon: 'format_italic' },
    { label: 'Underline', style: 'UNDERLINE', icon: 'format_underlined' },
    // { label: 'Monospace', style: 'CODE', icon: 'code_blocks' },
];

const InlineStyleControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    icon={type.icon}
                />
            )}
        </div>
    );
};

export default RichText;