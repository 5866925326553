import React from "react";
import Logo from '../../../assets/images/logo-white.svg';
import { DataContext } from '../../../Containers/DataProvider';
import { getAuth, signOut } from 'firebase/auth';

class Header extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    signOutHandler = () => {
        const auth = getAuth();
        
        signOut(auth)
            .then(function() {
                // window.location.reload();
            })
            .catch(function(error) {
                // An error happened
            });
    }


    render() {
        return (
            <div className='header'>
                <img src={Logo} className='logo' alt='Answers Automate' />
                <div className="dropdown-wrapper">
                    <p>
                        <span className="material-icons">
                            apps
                        </span>
                        <span>Quick actions</span>
                    </p>
                    <div className="dropdown">

                        {/* <span className="item">
                            Feature request
                        </span>

                        <span className="item">
                            Support
                        </span> */}

                        <span className="item" onClick={() => this.signOutHandler()}>
                            Logout
                        </span>

                        <span className="user">
                            <span className="secondary-color">Logged in as: </span>{this.context.user && this.context.user.email ? this.context.user.email : ''}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

Header.contextType = DataContext;
export default Header;
