import React from "react";

class InfoBox extends React.Component {
    render() {
        return (
            <div className={`${this.props.boxCssClasses} info-box`}>
                <div className= "text-wrapper">
                    {
                        this.props.icon ?
                            <span className={`material-icons ${this.props.iconCssClasses}`}>{this.props.icon}</span>
                            : ''
                    }
                    <div className="text" dangerouslySetInnerHTML={{ __html: this.props.infoText }}></div>
                </div>
                {
                    this.props.showButton === 'true' ?
                        <div className={`${this.props.buttonCssClasses}`} onClick={() => this.props.buttonAction()}>
                            <span>{this.props.buttonText}</span>
                        </div>
                        : ''
                }
            </div>
        );
    }

}

export default InfoBox;