import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import Notification from './Alert';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

class SaveButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showNotification: false
        }

        if (this.props.triggerAutoSave) {

            // setInterval(() => {
            //     this.save();
            // }, 60000);

        }

    }

    save = () => {

        if (this.props.disabled === 'false' || !this.props.disabled) {

            if (this.state.isLoading === false) {
                this.setState({isLoading: true});
                let payload = this.props.payload;

                if (this.props.dataToProcess && this.props.dataToProcess.variant === 'live') {

                    const property = this.props.dataToProcess.property;
                    payload[property].live = payload[property].draft;
                    payload[property].live.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();

                    if (this.props.db === 'content') {
                        this.context.getContentTable();
                    }

                }

                if (this.props.dataToProcess && this.props.dataToProcess.variant === 'draft') {

                    const property = this.props.dataToProcess.property;
                    payload[property].draft.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();

                }

                const currentDocRef = doc(db, this.props.db, this.props.docRef);
                updateDoc(currentDocRef, payload).then(() => {

                    // this.context.updateData(payload);

                    if (this.props.db === 'accounts') {
                        this.context.getAccountsTable();
                    }
                    if (this.props.db === 'content') {
                        this.context.getContentTable();
                    }

                    this.setState({isLoading: false});

                    document.body.classList.remove('modal-open', 'overflow-hidden');

                    Notification.updateNotification(
                        {
                            type: 'success',
                            title: 'Success',
                            message: 'Your data have been updated'
                        }
                    );

                })

            }

        }

    }

    static autoSave(context, dataToProcess, requestPayload, dbName, docRef) {

        let payload = requestPayload;

        if (dataToProcess && dataToProcess.variant === 'live') {

            const property = dataToProcess.property;
            payload[property].live = payload[property].draft;
            payload[property].live.lastUpdate = firebase.firestore.FieldValue.serverTimestamp()

        }

        if (dataToProcess && dataToProcess.variant === 'draft') {

            const property = dataToProcess.property;
            payload[property].draft.lastUpdate = firebase.firestore.FieldValue.serverTimestamp();

        }

        document.body.classList.add('autosave-loading');

        const currentDocRef = doc(db, dbName, docRef);
        updateDoc(currentDocRef, payload).then(() => {

            if (dbName === 'accounts') {
                context.getAccountsTable();
            }
            if (dbName === 'content') {
                context.getContentTable();
            }

            document.body.classList.remove('modal-open', 'overflow-hidden');
            document.body.classList.remove('modal-open', 'overflow-hidden');

            // Notification.updateNotification(
            //     {
            //         type: 'success',
            //         title: 'Success',
            //         message: 'Your data have been updated'
            //     }
            // );

        })

    }

    render() {
        return (
            <>
                <div className={`${this.props.cssClasses} ${this.state.isLoading === true ? "loading disabled" : ""}`} 
                    onClick={() => this.save()}>
                    <span dangerouslySetInnerHTML={{__html: this.props.buttonText}} />
                </div>
            </>
        );
    }
}

SaveButton.contextType = DataContext;
export default SaveButton;