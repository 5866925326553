import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import ColorField from '../../Dashboard/Components/ColorField';
import DOMPurify from 'dompurify';

class BodySettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('bodySettings', this.state.widgetSettings.draft.bodySettings);
            })
        }
    }

    updatePayload = (key, value) => {

        const cleanString = DOMPurify.sanitize(value);

        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.bodySettings[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('bodySettings', this.state.widgetSettings.draft.bodySettings);

        // SaveButton.autoSave(
        //     this.context,
        //     {variant: 'draft', property:'widgetSettings'},
        //     this.props.payload,
        //     "accounts",
        //     this.context.accountsRefId
        // );
    }

    render() {
        return (
            this.state.widgetSettings ?
            <>
                <div className='faq-form'>
                    <div className='two-columns'>
                        <ColorField 
                            wrapperCssClasses="w50"
                            color={this.state.widgetSettings.draft.bodySettings.backgroundColor || ''}
                            labelText="Background color"
                            // infoTooltip="Test tooltip text"
                            UpdateColor={this.updatePayload}
                            propToUpdate='backgroundColor'
                        />
                    </div>
                </div>
            </>
            : ''
        );
    }

}

BodySettings.contextType = DataContext;
export default BodySettings;