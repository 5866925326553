import React from "react";

class DeleteQuestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }


    render() {
        return (
            <>
                <span className="material-icons text-blue modal-icon">delete</span>
                <h2 className="title">Deleting "<span className="text-blue">{this.props.question.question}</span>" question?</h2>
                <div className="buttons">
                    <div className="secondary-button blue-button medium-button" onClick={() => { this.props.closeModal() }}>
                        <span>Cancel</span>
                    </div>
                    <div className={`primary-button blue-button medium-button ${this.state.isLoading === true ? "loading disabled" : ""}`} onClick={() => { this.props.deleteQuestion(this.props.index);this.setState({isLoading: true});}}>
                        <span>Delete</span>
                    </div>
                </div>
            </>
        );
    }

}

export default DeleteQuestion;