import React from 'react';
import Routes from './views/Routes';

class App extends React.Component {

  render() {
    return (
      <div className="content">
        <Routes />
      </div>
    );
  }
  
}

export default App;
