import React from "react";
import { DataContext } from '../../Containers/DataProvider';
import SaveButton from '../Dashboard/Components/SaveButton';
import WithCard from '../../hoc/WithCard';
import Categories from './Components/Categories';
// import InfoTooltip from '../Dashboard/Components/InfoTooltip';

class CategoriesEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    updatePayloadData = (key, value) => {
        this.payload[key] = value;
    }

    render() {
        const CategoriesCard = WithCard(Categories);
        // const dateFormat = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

        return (
            this.context.categories ?
            <div>
                <div className='header-actions'>
                <div>
                        {/* <p>{this.context.categories.live && this.context.categories.live.lastUpdate ? `Last publish on ${this.context.categories.live.lastUpdate}` : 'Not published yet'}, {this.context.categories && this.context.categories.draft.lastUpdate ? `Last saved on ${this.context.categories.draft.lastUpdate}` : 'Not saved yet'}</p> */}

                        <div className='flex-row flex-align-end'>
                            
                            {/* <InfoTooltip infoText={<p>{this.context.categories.live && this.context.categories.live.lastUpdate ? `Last publish: ${this.context.categories.live.lastUpdate.toDate().toLocaleDateString('en-US', dateFormat)}` : 'Not published yet'}<br/> {this.context.categories && this.context.categories.draft.lastUpdate ? `Last saved: ${this.context.categories.draft.lastUpdate.toDate().toLocaleDateString('en-US', dateFormat)}` : 'Not saved yet'}</p>} icon='info' cssClasses='arrow-bottom header-info text-yellow'/> */}
                            
                            <SaveButton 
                                payload={this.payload}
                                buttonText='Publish changes'
                                cssClasses={`${this.context.user && this.context.user.emailVerified === false ? "disabled" : ""} primary-button blue-button medium-button`}
                                disabled={`${this.context.user && this.context.user.emailVerified === false ? "true" : "false"}`}
                                dataToProcess={{variant: 'live', property:'categories'}} 
                                db="content"
                                docRef={this.context.contentRefId} />
                        </div>
                    </div>
                </div>
                <div className="mt-80 mb-80">
                    <div className="large-container">
                        <CategoriesCard title='Categories' isOpen='true' updatePayloadData={this.updatePayloadData} payload={this.payload} />
                    </div>
                </div>
            </div>
            : ''
        );
    }
}

CategoriesEditor.contextType = DataContext;
export default CategoriesEditor;
