import React from "react";
import { DataContext } from '../../Containers/DataProvider';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link, withRouter } from 'react-router-dom';
import InfoBox from '../Dashboard/Components/InfoBox';

import ConversationDisplay from './Components/ConversationDisplay';

class Conversations extends React.Component {

    constructor() {
        super();
        this.state = {
            messages: {},
            currentPage: 1,
            messagesPerPage: 15,
            isLoading: true,
            activeConversationId: ''
        }

    }

    componentDidMount() {
        const conversations = query(collection(db, "conversations"));
        const conversationsQuery = query(conversations, where("aid", "==", this.context.accountsRefId));

        const conversationsQuerySnapshot = getDocs(conversationsQuery);

        const { location } = this.props;
        const { pathname } = location;
        const conversationId = this.getConversationIdFromURL(pathname);

        conversationsQuerySnapshot.then((response) => {

            const conversationsDocsData = response.docs[0]?.data();

            if (conversationsDocsData?.messages) {
                const sortedMessages = Object.keys(conversationsDocsData.messages)
                    .sort((a, b) => {
                        const timestampA = conversationsDocsData.messages[a].timestamp.seconds * 1000 + Math.floor(conversationsDocsData.messages[a].timestamp.nanoseconds / 1000000);
                        const timestampB = conversationsDocsData.messages[b].timestamp.seconds * 1000 + Math.floor(conversationsDocsData.messages[b].timestamp.nanoseconds / 1000000);
                        return timestampB - timestampA; // Sort based on timestamp in descending order
                    });

                const totalPages = Math.ceil(sortedMessages.length / this.state.messagesPerPage);
                const pages = {};

                for (let i = 0; i < totalPages; i++) {
                    const start = i * this.state.messagesPerPage;
                    const end = start + this.state.messagesPerPage;
                    const page = sortedMessages.slice(start, end);
                    pages[i + 1] = page.reduce((accumulator, messageId) => {
                        accumulator[messageId] = conversationsDocsData.messages[messageId];
                        return accumulator;
                    }, {});
                }

                let activePage = 1;
                for (let i = 1; i <= totalPages; i++) {
                    if (Object.keys(pages[i]).includes(conversationId)) {
                        activePage = i;
                        break;
                    }
                }

                this.setState(
                    {
                        // messages: conversationsDocsData.messages,
                        pages,
                        isLoading: false,
                        currentPage: activePage,
                    },
                    () => {
                        if (conversationId && this.state.pages[this.state.currentPage][conversationId]) {
                            this.setState({ activeConversationId: conversationId }, () => {
                                this.props.history.push(`/dashboard/conversations/${conversationId}`);
                            });
                        } else {
                            const firstConversationId = Object.keys(this.state.pages[1])[0];
                            if (firstConversationId && this.state.pages[1][firstConversationId]) {
                                this.setState({ activeConversationId: firstConversationId });
                                this.props.history.push(`/dashboard/conversations/${firstConversationId}`);
                            } else {
                                // No conversations exist, handle the case as needed
                            }
                        }
                    }
                );
            } else {
                this.setState(
                    {
                        noConversations: true,
                        isLoading: false,
                    })
            }
        });
    }


    getConversationIdFromURL = (pathname) => {
        const parts = pathname.split('/');
        if (parts.length > 3 && parts[2] === 'conversations') {
            return parts[3];
        }
        return '';
    }


    renderConversationLinks() {
        const { pages, currentPage } = this.state;

        // Calculate the index of the conversations to be displayed on the current page
        // const indexOfLastConversation = currentPage * messagesPerPage;
        // const indexOfFirstConversation = indexOfLastConversation - messagesPerPage;
        const currentConversations = Object.keys(pages[currentPage]);

        return currentConversations.map((conversationId, index) => {
            const conversationData = pages[currentPage][conversationId];
            // const conversationDates = Object.keys(conversationData);
            // const latestKey = conversationDates.find((date) => date !== 'timestamp' && date !== 'read');
            const timestamp = conversationData.timestamp;
            const date = new Date(timestamp.seconds * 1000); // Convert timestamp to date object

            const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;
            const formattedTime = date.toLocaleTimeString();

            return (
                <div key={conversationId} className={`item ${this.state.activeConversationId === conversationId ? 'active' : ''}`}>
                    <Link
                        to={`/dashboard/conversations/${conversationId}`}
                        onClick={() => {
                            this.setState({
                                activeConversationId: conversationId
                            });
                        }}
                    >
                    <div>
                    <span className="date" title={conversationData?.userDetails?.email
                        ? conversationData.userDetails.email  : formattedDate}>
                        {conversationData?.userDetails?.email
                        ? conversationData.userDetails.email : formattedDate}
                    </span>
                    <span className="time">{formattedTime}</span>
                    </div>
                    </Link>
                </div>
            );
        });
    }

    renderPagination() {
        const { pages, currentPage } = this.state;
        const pageNumbers = Object.keys(pages).length;

        return (
            <div className="pagination">
                {currentPage > 1 ? (
                    <span
                        className="previous-page action crs-pointer"
                        onClick={() => {
                            const nextPage = currentPage - 1;
                            const conversations = Object.keys(pages[nextPage]);
                            const conversationId = conversations[0];
                            this.setState(
                                {
                                    currentPage: nextPage,
                                    activeConversationId: conversationId,
                                },
                                () => {
                                    this.props.history.push(`/dashboard/conversations/${conversationId}`);
                                }
                            );
                        }}
                    >
                        <span className="material-icons">arrow_back</span>
                    </span>
                ) : (
                        <span className="previous-page action"></span>
                    )}

                {currentPage && <span className="current-page">{currentPage} of {Object.keys(this.state.pages).length}</span>}

                {currentPage < pageNumbers ? (
                    <span
                        className="next-page action crs-pointer"
                        onClick={() => {
                            const nextPage = currentPage + 1;
                            const conversations = Object.keys(pages[nextPage]);
                            const conversationId = conversations[0];
                            this.setState(
                                {
                                    currentPage: nextPage,
                                    activeConversationId: conversationId,
                                },
                                () => {
                                    this.props.history.push(`/dashboard/conversations/${conversationId}`);
                                }
                            );
                        }}
                    >
                        <span className="material-icons">arrow_forward</span>
                    </span>
                ) : (
                        <span className="next-page action"></span>
                    )}
            </div>
        );
    }




    render() {

        return (
            <div className="conversations">
                {

                    this.state.isLoading && 
                    <div className='loader-overlay'>
                        <div className='loader'></div>
                    </div>

                }

                { !this.state.isLoading && !this.state.noConversations &&

                    <div className="conversations-wrapper">
                    <div className="conversations-nav">
                        <div className="conversation-list">{this.renderConversationLinks()}</div>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.pages[this.state.currentPage][this.state.activeConversationId] &&
                        <ConversationDisplay messages={this.state.pages[this.state.currentPage][this.state.activeConversationId]} />
                    }
                    </div>
                
                }

                {
                    !this.state.isLoading && this.state.noConversations &&

                    <div className="mt-80 mb-80">
                    <div className="large-container">
                        <div className="installation-instructions">
                            <InfoBox
                                infoText="<h2>You haven't initiated any conversations yet!</h2><p>To start chatting, please publish your widget.</p>"
                                icon='add_circle'
                                iconCssClasses='text-blue'
                                showButton="false"
                                buttonText="Resend verification email"
                                buttonCssClasses={`primary-button blue-button medium-button ${this.state.sendEmailVerificationIsLoading ? 'loading disabled' : ''}`}
                                boxCssClasses=""
                                buttonAction={this.resendVerificationEmail}
                            />
                        </div>
                    </div>
                </div>

                }

            </div>
        );
    }
}

Conversations.contextType = DataContext;
// export default Conversations;
export default withRouter(Conversations);