import React from "react";
import { DataContext } from '../../../../Containers/DataProvider';
import DOMPurify from 'dompurify';

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.payload = {};
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('chatSection', this.state.widgetSettings.draft.chatSection);
            })
        }
    }

    updatePayload = (key, value) => {

        const cleanString = DOMPurify.sanitize(value);
        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.chatSection[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('chatSection', this.state.widgetSettings.draft.chatSection);

    }

    render() {
        return (
            this.state.widgetSettings ?
                <>
                    <div className='faq-form'>
                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Title</label>
                                <input type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.title || ''} onChange={(e) => this.updatePayload('title', e.currentTarget.value)}
                                />
                            </div>
                        </div>
                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Description</label>
                                <textarea type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.description || ''} onChange={(e) => this.updatePayload('description', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset input-toggle'>
                                <label>Visibility</label>
                                <div className="input">
                                    <p>Show this section?</p>
                                    <div className={`switcher ${this.state.widgetSettings.draft.chatSection.visible ? 'on' : 'off'}`}  onClick={() => this.updatePayload('visible', !this.state.widgetSettings.draft.chatSection.visible)}>
                                        <div className='control'></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                : ''
        );
    }

}

Chat.contextType = DataContext;
export default Chat;

