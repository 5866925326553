import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import WithCard from '../../../hoc/WithCard';
import ChatSettings from './Chat Settings/ChatSettings'

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    render() {

        const ChatSettingsCard = WithCard(ChatSettings);
        this.payload = this.props.payload;

        return (
            <div>
                <div className="mt-80 mb-80">
                    <div className='large-container'>
                        <ChatSettingsCard updatePayloadData={this.props.updatePayloadData} payload={this.payload} title='Chat settings' isOpen={true} />
                    </div>
                </div>
            </div>
        );
    }
}

Chat.contextType = DataContext;
export default Chat;
