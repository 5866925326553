import React from 'react';
import axios from "axios";
import { baseURL } from "../../constants/index";

class ReconfirmEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false
        }
    }

    componentDidMount() {
        const values = {
            email: this.props.email,
        };
        axios
            .post(`${baseURL}user/register/resend`, values)
            .then(response => {
                if (response.data.errors) {

                }
                if (response.data.success === true) {

                    this.setState({ success: true });

                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                {this.state.success ?
                    (<p class="general-form-success text-center">
                        An new code was sent to {this.props.email}.
                    </p>) : ''
                }
            </>
        );
    }

}

export default ReconfirmEmail;
