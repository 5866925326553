import React from 'react';
// import lodashMerge from 'lodash.merge';
import { notificationTime } from "../constants/index";
import { getAuth } from "firebase/auth";
import { collection, updateDoc, query, getDocs, where, doc, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const DataContext = React.createContext();

class DataProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            theme: '',
            alerts: [],
            user: {},
        }
    }

    // updateData = (obj) => {
    //     const newState = lodashMerge({}, this.state.account, obj);
    //     this.setState({
    //         account: newState,
    //     });
    // }

    updateTheme = theme => {
        if (!theme) {
            const themeColor = this.state.data.user.account_settings.theme;
            this.setState({ theme: themeColor },
                () => {
                    // document.body.classList.add(this.state.theme);
                });
        } else {
            const values = this.state.data;
            values.user.account_settings.theme = theme;
            document.body.className = [];
            this.setState({
                theme: theme,
                data: values
            },
                () => {
                    // document.body.classList.add(this.state.theme);
                });
        }
    }

    updateAlerts = (alert) => {

        const stateAlerts = this.state.alerts;
        this.state.alerts.push(alert);
        this.setState({
            alerts: stateAlerts,
        })

        const removeAlert = setTimeout(() => {
            const updatedAlerts = this.state.alerts;
            updatedAlerts.splice(0, 1);
            this.setState({
                alerts: updatedAlerts,
            })
        }, notificationTime);

        if (this.state.length > 0) {
            removeAlert();
        }

    }

    removeAlert = (AlertIndex) => {
        const stateAlerts = this.state.alerts;
        stateAlerts.splice(AlertIndex, 1);
        this.setState({
            alerts: stateAlerts,
        });
    }

    async componentDidMount() {

        const auth = getAuth();
        if (auth.currentUser) {

            this.setState({
                user: auth.currentUser
            });

            this.uid = auth.currentUser.uid;

            const accounts = query(collection(db, "accounts"));
            const accountsQuery = query(accounts, where("users", "array-contains", this.uid));
            const accountsQuerySnapshot = getDocs(accountsQuery);

            accountsQuerySnapshot.then((response) => {

                response.forEach((docRef) => {

                    const docData = docRef.data();
                    this.docId = docRef.id;

                    // Create & get content table
                    const content = query(collection(db, "content"));
                    const contentQuery = query(content, where("aid", "==", docRef.id));
                    const contentQuerySnapshot = getDocs(contentQuery);

                    contentQuerySnapshot.then((response) => {

                        let existingContentDocs = 0;
                        let contentDocsData;
                        let docRefContentId;

                        response.forEach((docRefContent) => {

                            existingContentDocs += 1;
                            contentDocsData = docRefContent.data();
                            docRefContentId = docRefContent.id;

                        });

                        if (existingContentDocs === 0) {

                            addDoc(collection(db, 'content'), {
                                aid: docRef.id,
                                categories: {
                                    draft: {
                                        lastUpdate: '',
                                        category: []
                                    }
                                },
                                questions: {
                                    draft: {
                                        lastUpdate: '',
                                        question: []
                                    }
                                }
                            }).then(() => {

                                this.getContentTable();

                            })

                        } else if (existingContentDocs !== 0) {
                            this.setState({
                                contentCurrentDocRef: docRefContentId,
                                categories: contentDocsData.categories,
                                questions: contentDocsData.questions,
                            });
                        }

                    }).catch((error) => {
                        console.log(error);
                    });
                    // Create & get content table

                    if (!docData.activeSubscription) {

                        const accountsCurrentDocRef = doc(db, "accounts", docRef.id);

                        const subscriptions = [
                            {
                                categories: 5,
                                questions: 25,
                                active: true,
                                name: 'Initial Free',
                                conversations: 20
                            }
                        ]

                        const activeSubscription = subscriptions.find(subscription => subscription.active === true);

                        this.setState({
                            activeSubscription: activeSubscription,
                        });

                        updateDoc(accountsCurrentDocRef, {
                            activeSubscription: activeSubscription
                        });

                    } else if (!docData.activeSubscription.conversations) {

                        const accountsCurrentDocRef = doc(db, "accounts", docRef.id);

                        const activeSubscription = docData.activeSubscription;
                        activeSubscription.conversations = 20;

                        this.setState({
                            activeSubscription: activeSubscription,
                        });

                        updateDoc(accountsCurrentDocRef, {
                            activeSubscription: activeSubscription
                        });

                    } else {

                        // const activeSubscription = docData.activeSubscription.find(subscription => subscription.active === true);

                        this.setState({
                            activeSubscription: docData.activeSubscription,
                        });

                    }

                    if (!docData.widgetSettings) {

                        const accountsCurrentDocRef = doc(db, "accounts", docRef.id);

                        updateDoc(accountsCurrentDocRef, {
                            widgetSettings: {
                                draft: {
                                    headerSettings: {
                                        backgroundColor: '#5A93FF',
                                        textColor: '#ffffff',
                                        headerText: 'Help center',
                                    },
                                    iconSettings: {
                                        backgroundColor: '#5A93FF',
                                        iconColor: '#ffffff',
                                        iconType: 'icon-type-1',
                                        widgetTooltip: '',
                                    },
                                    textSettings: {
                                        primaryColor: '#2D3854',
                                        secondaryColor: '#a9b3c2',
                                        tertiaryColor: '#5A93FF',
                                        font: {
                                            family: 'Poppins',
                                            variant: 'Regular'
                                        }
                                    },
                                    bodySettings: {
                                        backgroundColor: '#fff',
                                    },
                                    widgetPosition: {
                                        position: 'bottom-left',
                                        margins: {
                                            top: 50,
                                            left: 50,
                                            right: 50,
                                            bottom: 50
                                        }
                                    },
                                    faqsSection: {
                                        visible: true,
                                        title: 'FAQs',
                                        description: 'Get answers to most commonly asked questions about the product, payment and more.'
                                    },
                                    chatSection: {
                                        visible: true,
                                        title: 'Live chat',
                                        description: 'Engage with our virtual assistant for seamless communication.',
                                        companyName: 'Your product/company name',
                                        welcomeMessage: "Welcome to our chat! I’m an AI powered by ChatGPT, here to assist you. I’ll do my best to provide you with helpful and informative responses. Let’s get started!",
                                        responseStyle: [
                                            'professional'
                                        ],
                                        avatarUrl: '',
                                        assistantName: '',
                                        transcriptEmail: '',
                                        askForTheEmailPrompt: '',
                                        emailConfirmationMessage: ''
                                        
                                    },
                                    lastUpdate: '',
                                },
                                live: {
                                    headerSettings: {
                                        backgroundColor: '#5A93FF',
                                        textColor: '#ffffff',
                                        headerText: 'Help center',
                                    },
                                    iconSettings: {
                                        backgroundColor: '#5A93FF',
                                        iconColor: '#ffffff',
                                        iconType: 'icon-type-1',
                                        widgetTooltip: '',
                                    },
                                    textSettings: {
                                        primaryColor: '#2D3854',
                                        secondaryColor: '#a9b3c2',
                                        tertiaryColor: '#5A93FF',
                                        font: {
                                            family: 'Poppins',
                                            variant: 'Regular'
                                        }
                                    },
                                    bodySettings: {
                                        backgroundColor: '#fff',
                                    },
                                    widgetPosition: {
                                        position: 'bottom-left',
                                        margins: {
                                            top: 50,
                                            left: 50,
                                            right: 50,
                                            bottom: 50
                                        }
                                    },
                                    faqsSection: {
                                        visible: true,
                                        title: 'FAQs',
                                        description: 'Get answers to most commonly asked questions about the product, payment and more.'
                                    },
                                    chatSection: {
                                        visible: true,
                                        title: 'Live chat',
                                        description: 'Engage with our virtual assistant for seamless communication.',
                                        companyName: 'Your product/company name',
                                        welcomeMessage: "Welcome to our chat! I’m an AI powered by ChatGPT, here to assist you. I’ll do my best to provide you with helpful and informative responses. Let’s get started!",
                                        responseStyle: [
                                            'professional'
                                        ],
                                        avatarUrl: '',
                                        assistantName: '',
                                        transcriptEmail: '',
                                        askForTheEmailPrompt: '',
                                        emailConfirmationMessage: '',
                                    },
                                    lastUpdate: '',
                                },
                            }
                        }).then(() => {
                            this.getAccountsTable();
                        })

                    } else {

                        const accountsCurrentDocRef = doc(db, "accounts", docRef.id);

                        let updateData = docData;
                        let updateDataFlag = false;

                        if (!docData.widgetSettings.draft.chatSection.transcriptEmail) {
                            updateData.widgetSettings.draft.chatSection.transcriptEmail = ''; 
                            updateDataFlag = true;
                        }
                    
                        if (!docData.widgetSettings.draft.chatSection.askForTheEmailPrompt) {
                            updateData.widgetSettings.draft.chatSection.askForTheEmailPrompt = '';
                            updateDataFlag = true;
                        }
                    
                        if (!docData.widgetSettings.live.chatSection.transcriptEmail) {
                            updateData.widgetSettings.live.chatSection.transcriptEmail = '';
                            updateDataFlag = true;
                        }
                    
                        if (!docData.widgetSettings.live.chatSection.askForTheEmailPrompt) {
                            updateData.widgetSettings.live.chatSection.askForTheEmailPrompt = '';
                            updateDataFlag = true;
                        }

                        if (!docData.widgetSettings.draft.chatSection.emailConfirmationMessage) {
                            updateData.widgetSettings.draft.chatSection.emailConfirmationMessage = '';
                            updateDataFlag = true;
                        }

                        if (!docData.widgetSettings.live.chatSection.emailConfirmationMessage) {
                            updateData.widgetSettings.live.chatSection.emailConfirmationMessage = '';
                            updateDataFlag = true;
                        }

                        if (!docData.widgetSettings.draft.iconSettings.widgetTooltip) {
                            updateData.widgetSettings.draft.iconSettings.widgetTooltip = '';
                            updateDataFlag = true;
                        }

                        if (!docData.widgetSettings.live.iconSettings.widgetTooltip) {
                            updateData.widgetSettings.live.iconSettings.widgetTooltip = '';
                            updateDataFlag = true;
                        }

                        if (updateDataFlag) {

                            updateDoc(accountsCurrentDocRef, updateData).then(() => {
                                this.getAccountsTable();
                            });

                        }

                        this.setState({
                            accountsCurrentDocRef: docRef.id,
                            account: updateData,
                            isLoading: false,
                        });

                    }

                });

            }).catch((error) => {
                console.log(error);
            });

        }

    }

    getAccountsTable = () => {

        const accounts = query(collection(db, "accounts"));
        const accountsQuery = query(accounts, where("users", "array-contains", this.uid));
        const accountsQuerySnapshot = getDocs(accountsQuery);

        accountsQuerySnapshot.then((response) => {

            response.forEach((docRef) => {

                this.setState({
                    accountsCurrentDocRef: docRef.id,
                    account: docRef.data(),
                    isLoading: false,
                });

            });

        }).catch((error) => {
            console.log(error);
        });

    }

    getContentTable = () => {

        const content = query(collection(db, "content"));
        const contentQuery = query(content, where("aid", "==", this.docId));
        const contentQuerySnapshot = getDocs(contentQuery);

        contentQuerySnapshot.then((response) => {

            response.forEach((docRef) => {
                this.setState({
                    contentCurrentDocRef: docRef.id,
                    categories: docRef.data().categories,
                    questions: docRef.data().questions,
                });

            });

        }).catch((error) => {
            console.log(error);
        });

    }

    render() {
        return (
            <>
                { this.state.account === undefined || this.state.categories === undefined || this.state.questions === undefined || this.state.activeSubscription === undefined ?
                    <div className='loader-overlay'>
                        <div className='loader'></div>
                    </div>
                    :
                    <DataContext.Provider
                        value={
                            {
                                account: this.state.account,
                                theme: this.state.theme,
                                accountsRefId: this.state.accountsCurrentDocRef,
                                user: this.state.user,
                                categories: this.state.categories,
                                questions: this.state.questions,
                                contentRefId: this.state.contentCurrentDocRef,
                                activeSubscription: this.state.activeSubscription,
                                updateTheme: this.updateTheme,
                                updateData: this.updateData,
                                // updateAlerts: this.updateAlerts,
                                removeAlert: this.removeAlert,
                                getAccountsTable: this.getAccountsTable,
                                getContentTable: this.getContentTable,
                                alerts: this.state.alerts
                            }
                        }
                    >
                        {this.state.account ? this.props.children : ''}
                    </DataContext.Provider>
                }
            </>
        )
    }
}

export { DataProvider, DataContext };