import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import RadioButton from '../../Dashboard/Components/RadioButton';
import DOMPurify from 'dompurify';

class WidgetPositiion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                // widgetSettings: this.context.account.widgetSettings,
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('widgetPosition', this.state.widgetSettings.draft.widgetPosition);
                this.updatePosition();
            });
        }
    }

    updatePayload = (key, value) => {
        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.widgetPosition[key] = value;
        this.setState(newState, () => {
            this.updatePosition();
        })
        this.props.updatePayloadData('widgetPosition', this.state.widgetSettings.draft.widgetPosition);

        // SaveButton.autoSave(
        //     this.context,
        //     {variant: 'draft', property:'widgetSettings'},
        //     this.props.payload,
        //     "accounts",
        //     this.context.accountsRefId
        // );

    }

    updatePosition = () => {

        const bottomLeft = this.state.widgetSettings.draft.widgetPosition.position === 'bottom-left';
        const bottomRight = this.state.widgetSettings.draft.widgetPosition.position === 'bottom-right';

        this.setState({
            bottomLeft: bottomLeft,
            bottomRight: bottomRight,
        })

    }

    updateMargin = (position, value) => {

        const cleanString = DOMPurify.sanitize(value);
        let margins = this.state.widgetSettings.draft.widgetPosition.margins;
        margins[position] = cleanString;
        this.updatePayload('margins', margins);

    }

    render() {
        return (
            this.state.widgetSettings ?
            <>
                <div className="position-field flex-row">
                    <RadioButton label="Bottom left" checked={this.state.bottomLeft} updatePayload={this.updatePayload} keyToUpdate="position" valueToUpdate="bottom-left" />
                    <div className="margin-wrapper faq-form">
                        <div className="flex-row">
                            {
                                this.state.bottomLeft ?
                                <div className="fieldset">
                                    <label>Left</label>
                                    <input type='number' className="input" defaultValue={this.state.widgetSettings.draft.widgetPosition.margins.left || ''} onChange={(e) => this.updateMargin('left', e.currentTarget.value)} />
                                </div> : ''
                            }
                            <div className="fieldset">
                                <label>Bottom</label>
                                <input type='number' className="input" defaultValue={this.state.widgetSettings.draft.widgetPosition.margins.bottom || ''} onChange={(e) => this.updateMargin('bottom', e.currentTarget.value)}/>
                            </div>
                            { 
                                this.state.bottomRight ?
                                <div className="fieldset">
                                    <label>Right</label>
                                    <input type='number' className="input" defaultValue={this.state.widgetSettings.draft.widgetPosition.margins.right || ''} onChange={(e) => this.updateMargin('right', e.currentTarget.value)}/>
                                </div> : ''

                            }
                        </div>
                    </div>
                    <RadioButton label="Bottom right" checked={this.state.bottomRight} updatePayload={this.updatePayload} keyToUpdate="position" valueToUpdate="bottom-right" />
                </div>
            </>
            : ''
        );
    }

}

WidgetPositiion.contextType = DataContext;
export default WidgetPositiion;