import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import ColorField from '../../Dashboard/Components/ColorField';
import DOMPurify from 'dompurify';

class TextSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                // widgetSettings: this.context.account.widgetSettings,
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('textSettings', this.state.widgetSettings.draft.textSettings);
            })
        }
    }

    updatePayload = (key, value, parentKey) => {
        const cleanString = DOMPurify.sanitize(value);
        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.textSettings[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('textSettings', this.state.widgetSettings.draft.textSettings);

        // SaveButton.autoSave(
        //     this.context,
        //     {variant: 'draft', property:'widgetSettings'},
        //     this.props.payload,
        //     "accounts",
        //     this.context.accountsRefId
        // );
    }

    render() {
        return (
            this.state.widgetSettings ?
            <>
                <div className='faq-form'>
                    <div className='three-columns'>
                        <ColorField 
                            wrapperCssClasses=""
                            color={this.state.widgetSettings.draft.textSettings.primaryColor || ''}
                            labelText="Primary color"
                            // infoTooltip="Applies to headings"
                            UpdateColor={this.updatePayload}
                            propToUpdate='primaryColor'
                        />
                        <ColorField 
                            wrapperCssClasses=""
                            color={this.state.widgetSettings.draft.textSettings.secondaryColor || ''}
                            labelText="Secondary color"
                            // infoTooltip="Applies to paragraphs"
                            UpdateColor={this.updatePayload}
                            propToUpdate='secondaryColor'
                        />
                        <ColorField 
                            wrapperCssClasses=""
                            color={this.state.widgetSettings.draft.textSettings.tertiaryColor || ''}
                            labelText="Tertiary color"
                            // infoTooltip="Applies to links, icons, blockquotes"
                            UpdateColor={this.updatePayload}
                            propToUpdate='tertiaryColor'
                        />
                    </div>
                </div>
            </>
            : ''
        );
    }

}

TextSettings.contextType = DataContext;
export default TextSettings;