import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import ColorField from '../../Dashboard/Components/ColorField';
import DOMPurify from 'dompurify';
// import InfoTooltip from '../../Dashboard/Components/InfoTooltip';

class HeaderSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('headerSettings', this.state.widgetSettings.draft.headerSettings);
            })
        }
    }

    updatePayload = (key, value) => {

        const cleanString = DOMPurify.sanitize(value);

        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.headerSettings[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('headerSettings', this.state.widgetSettings.draft.headerSettings);

        // SaveButton.autoSave(
        //     this.context,
        //     {variant: 'draft', property:'widgetSettings'},
        //     this.props.payload,
        //     "accounts",
        //     this.context.accountsRefId
        // );
    }

    render() {
        return (
            this.state.widgetSettings ?
                <>
                    <div className='faq-form'>
                        <div className='two-columns'>
                            <ColorField
                                wrapperCssClasses="w50"
                                color={this.state.widgetSettings.draft.headerSettings.backgroundColor || ''}
                                labelText="Background color"
                                // infoTooltip="Test tooltip text"
                                UpdateColor={this.updatePayload}
                                propToUpdate='backgroundColor'
                            />
                            <ColorField
                                wrapperCssClasses="w50"
                                color={this.state.widgetSettings.draft.headerSettings.textColor || ''}
                                labelText="Text color"
                                // infoTooltip="Test tooltip text"
                                UpdateColor={this.updatePayload}
                                propToUpdate='textColor'
                            />
                        </div>
                        <div className="one-column">
                            <div className="fieldset">
                                <label>Widget title 
                                    {/* <InfoTooltip infoText='Will appear in the widget header' icon='info'/> */}
                                </label>
                                <input type="text" className="input" onChange={(e) => this.updatePayload('headerText', e.currentTarget.value)} defaultValue={this.state.widgetSettings.draft.headerSettings.headerText || ''} />
                            </div>
                        </div>
                    </div>
                </>
                : ''
        );
    }

}

HeaderSettings.contextType = DataContext;
export default HeaderSettings;