import React, { Component } from 'react';

export default function WithCard(WrappedComponent){
    return class extends Component{
        constructor(props) {
            super(props);
            this.state = {
                isOpen: this.props.isOpen,
            }
        }

        openCard = () => {
            this.setState({
                isOpen: !this.state.isOpen,
            })
        }

        render(){
            return (
                <div className='card'>
                    <div className={`card-header ${this.state.isOpen ? 'opened' : ''}`} 
                    // onClick={() => this.openCard()}
                    >
                        <h2 className='primary-color'>{this.props.title}</h2>
                        {/* <i className='icon-plus primary-color'></i> */}
                    </div>
                    {this.state.isOpen ?
                        <div className='card-body'>
                            <WrappedComponent {...this.props}/>
                        </div>
                    : ''}
                </div>
            );
        }
    } 
}
