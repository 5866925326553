import React from "react";
import { Link } from "react-router-dom";
import Tabs from "./Tabs";
import ReconfirmEmail from './ReconfirmEmail';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import DOMPurify from 'dompurify';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      emailErrorMessage: "Invalid email address",
      userNotConfirmed: false,
      reconfirmEmailSent: false,
      apiEmailErrors: "",
      password: "",
      error: "",
      passwordError: false,
      passwordErrorMessage: "Enter your password",
      apiPasswordErrors: "",
      showPassword: false,
      isSubmitting: false,
      isLoggenIn: false,
      auth: getAuth()
    };

  }

  validateEmail = event => {

      const cleanString = DOMPurify.sanitize(event.target.value);

      this.setState({ email: cleanString });
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (cleanString.length < 1) {
        this.setState({ emailError: false });
        return true;
      }
      if (emailRegex.test(cleanString)) {
        this.setState({ emailError: false });
        return true;
      } else {
        this.setState({ emailError: true });
        return false;
      }

  };

  validatePassword = event => {

      const cleanString = DOMPurify.sanitize(event.target.value);

      this.setState({ password: cleanString });
      if (cleanString.length < 1) {
        this.setState({ passwordError: false });
        return true;
      }
      if (cleanString.length > 4) {
        this.setState({ passwordError: false });
        return true;
      } else {
        this.setState({ passwordError: true });
        return false;
      }
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ apiEmailErrors: '', apiPasswordErrors: '', formSubmitted: true });

    if (this.state.email.length === 0) {
      this.setState({ emailError: true });
    }

    if (this.state.password.length === 0 || this.state.password.length < 7) {
      this.setState({ passwordError: true });
    }

    if (
      this.state.email.length > 0 &&
      this.state.emailError === false &&
      this.state.password.length > 7 &&
      this.state.passwordError === false
    ) {
      this.setState({ isSubmitting: true });

      signInWithEmailAndPassword(this.state.auth, this.state.email, this.state.password)
        .then((userCredential) => {
          // Signed in 
          this.setState({ isLoggenIn: true, isSubmitting: false });
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/user-not-found') {

            this.setState({ apiEmailErrors: 'User not found.', isSubmitting: false });

          }

          if (errorCode === 'auth/wrong-password') {

            this.setState({ apiPasswordErrors: 'Wrong password.', isSubmitting: false });

          }
          
        });
    }
  };


  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }


  render() {
    return (
      <>
        <div className='animated-wrapper'>
          <Tabs />
          <div className="form-wrapper">
            <form className="faq-form" onSubmit={this.handleSubmit}>
              {this.state.apiEmailErrors.length > 0 ? (
                <p className="general-form-error text-center">
                  
                  {this.state.apiEmailErrors} <Link to="/auth/register">Create a new account</Link>.
                </p>
              ) : (
                  ""
                )}
              {this.state.apiPasswordErrors.length > 0 ? (
                <p className="general-form-error text-center">
                  
                  {this.state.apiPasswordErrors} <Link to="/auth/forgot">I forgot my password</Link>.
                </p>
              ) : (
                  ""
                )}
              {this.state.userNotConfirmed ? (
                <p className="general-form-error text-center">
                  
                  Account not confirmed. <span className='fake-link' onClick={() => this.resendConfirmationEmail()}>Get a new confirm link</span>.
                </p>
              ) : (
                  ""
                )}
                {this.state.reconfirmEmailSent ? <ReconfirmEmail email={this.state.email}/> : ''}
              <div className="fieldset">
                <label>Email address</label>
                <input
                  type="email"
                  className={`input ${
                    this.state.emailError ? "has-error" : ""
                    }`}
                  placeholder="name@company.com"
                  onChange={this.validateEmail}
                />
                {this.state.emailError ? (
                  <div className="error">
                    
                    {this.state.emailErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
              </div>
              <div className="fieldset">
                <label>Password</label>
                <input
                  type={`${this.state.showPassword ? 'text' : 'password'}`}
                  className={`input ${
                    this.state.passwordError ? "has-error" : ""
                    }`}
                  placeholder="********"
                  onChange={this.validatePassword}
                />
                {this.state.passwordError ? (
                  <div className="error">
                    
                    {this.state.passwordErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
 
                <div className='password-action material-icons' onClick={this.showPassword}>
                  {this.state.showPassword ? 'visibility' : 'visibility_off'}
                </div>
              </div>
              <div className="forgot">
                <Link to="/auth/forgot" className="forgot">
                  I forgot my password
                </Link>
              </div>
              <button
                type="submit"
                className={`primary-button blue-button ${this.state.isSubmitting ? 'loading' : ''}`}
                disabled={this.state.isSubmitting}
              ><span>Login</span></button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
