import React from "react";
import { DataContext } from '../../Containers/DataProvider';
import { sendEmailVerification } from "firebase/auth";
import WithCard from '../../hoc/WithCard';
import InfoBox from '../Dashboard/Components/InfoBox';

class InstallWidgetWrapper extends React.Component {

    constructor() {
        super();
        this.state = {
            sendEmailVerificationIsLoading: false,
            copyButtonIsLoading: false,
        }
    }

    resendVerificationEmail = () => {

        this.setState({
            sendEmailVerificationIsLoading: true,
        });

        sendEmailVerification(this.context.user).then(() => {

            this.setState({
                sendEmailVerificationIsLoading: false,
            });
            // this.context.updateAlerts({
            //     type: 'success',
            //     title: 'Success',
            //     message: 'Email Verification Sent!'
            // });

        }).catch(() => {
            this.setState({
                sendEmailVerificationIsLoading: false,
            });
            // this.context.updateAlerts({
            //     type: 'error',
            //     title: 'Something went wrong',
            //     message: 'Try again in a few minutes.'
            // });
        })

    };

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({
            copyButtonIsLoading: true,
        });

        setTimeout(() => {

            this.setState({
                copyButtonIsLoading: false,
            });

        }, 1000);
    };

    installationHtml = () => {

        const iframeSrc = `<script src="https://static.answersautomate.com/widget.js?widget-id=${this.context.accountsRefId}"></script>`

        const installationHtml = (
            <div className="installation-steps">
                <ol>
                    <li>
                        Add your brand settings, categories & questions.
                    </li>
                    <li>
                        Publish the changes.
                    </li>
                    <li>
                        Copy the following code:
                        <code>
                            {iframeSrc.toString()}
                            {
                                document.queryCommandSupported('copy') &&
                                <span className={`material-icons copy-button ${this.state.copyButtonIsLoading === true ? "disabled" : ""}`}
                                    onClick={this.copyToClipboard}>
                                    {this.state.copyButtonIsLoading ? 'done' : 'content_copy'}
                                </span>
                            }
                        </code>
                    </li>
                    <li>
                        Paste the code at the end of the body tag on your website.
                    </li>
                    <li>
                        You're all set!
                    </li>
                </ol>

                {/* <div className="text-center mt-40">
                    {

                        document.queryCommandSupported('copy') &&
                        <div>
                            <button
                                className={`primary-button blue-button medium-button ${this.state.copyButtonIsLoading === true ? "disabled" : ""}`}
                                onClick={this.copyToClipboard}>
                                {this.state.buttonMessage}
                            </button>
                        </div>
                    }
                </div> */}
                <form>
                    <textarea
                        ref={(textarea) => this.textArea = textarea}
                        value={iframeSrc} readOnly
                    />
                </form>

            </div>
        )

        return installationHtml;

    }

    render() {
        const InstallationCard = WithCard(this.installationHtml);
        return (
            <>

                {
                    this.context.user && this.context.user.emailVerified === false ?
                        <div className="mt-80 mb-80">
                            <div className="large-container">
                                <div className="installation-instructions">
                                    <InfoBox
                                        infoText='<h2>Email not verified</h2><p>To publish your widget, first you need to confim your email.</p>'
                                        icon='notification_important'
                                        iconCssClasses='text-blue'
                                        showButton="true"
                                        buttonText="Resend verification email"
                                        buttonCssClasses={`primary-button blue-button medium-button mt-40 ${this.state.sendEmailVerificationIsLoading ? 'loading disabled' : ''}`}
                                        boxCssClasses=""
                                        buttonAction={this.resendVerificationEmail}
                                    />
                                </div>
                            </div>
                        </div>
                        // <div className="mt-80 mb-80">
                        //     <div className="large-container">
                        //         <div className="card">
                        //             <div className={`primary-button blue-button medium-button  ${this.state.isLoading === true ? "loading disabled" : ""}`}
                        //                 onClick={() => this.resendVerificationEmail()}>
                        //                 <span>Resend verification email</span>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                        :
                        <div className="mt-80 mb-80">
                            <div className="large-container">
                                <div className="installation-instructions">
                                    <InstallationCard title='Widget Installation Steps' isOpen='true' />
                                </div>
                            </div>
                        </div>

                }

            </>
        );
    }
}

InstallWidgetWrapper.contextType = DataContext;
export default InstallWidgetWrapper;