import React, { Component } from 'react';

class Notification extends Component {
    state = {
        alerts: []
    };

    static updateNotification(message) {
        const existingAlerts = this.instance.state.alerts;
        existingAlerts.push(message)
        this.instance.setState({ alerts: existingAlerts });

        setTimeout(() => {
            const updatedAlerts = this.instance.state.alerts;
            updatedAlerts.splice(0, 1);
            this.instance.setState({
                alerts: updatedAlerts,
            })
        }, 5000);

    }

    removeAlert(AlertIndex) {
        const stateAlerts = this.state.alerts;
        stateAlerts.splice(AlertIndex, 1);
        this.setState({
            alerts: stateAlerts,
        });
    }

    componentDidMount() {
        Notification.instance = this;
    }

    render() {
        return (
            <div className='alerts'>
                {this.state.alerts.map((alert, index) =>
                    <div className={`notification ${alert.type}`} key={index}>
                        <i className='icon-close'
                            onClick={() => { this.removeAlert(index) }}></i>
                        {alert.type === 'success' ?
                            <div className='notification-wrapper'>
                                <i className='icon-check'></i>
                                <h4>{alert.title}</h4>
                                <p>{alert.message}</p>
                            </div>
                            : ''
                        }
                        {alert.type === 'error' ?
                            <div className='notification-wrapper'>
                                <i className='icon-check'></i>
                                <h4>{alert.title}</h4>
                                <p>{alert.message}</p>
                            </div>
                            : ''
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default Notification;
