import React from "react";
import { DataContext } from '../../Containers/DataProvider';
import SaveButton from '../Dashboard/Components/SaveButton';
import WithCard from '../../hoc/WithCard';
// import Categories from './Components/Categories'
import Questions from './Components/Questions';
// import InfoTooltip from '../Dashboard/Components/InfoTooltip';

class QuestionsEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    updatePayloadData = (key, value) => {
        this.payload[key] = value;
    }

    render() {
        const QuestionsCard = WithCard(Questions);

        return (
            this.context.categories ?
            <div>
                <div className='header-actions'>
                <div>
                        {/* <p>{this.context.questions.live && this.context.questions.live.lastUpdate ? `Last publish on ${this.context.questions.live.lastUpdate}` : 'Not published yet'}, {this.context.questions && this.context.questions.draft.lastUpdate ? `Last saved on ${this.context.questions.draft.lastUpdate}` : 'Not saved yet'}</p> */}

                        <div className='flex-row flex-align-end'>

                            {/* {this.context.questions.live && this.context.questions.draft.lastUpdate.seconds > this.context.questions.live.lastUpdate.seconds || 0 ?
                                <InfoTooltip infoText='You have unpublished changes.' icon='warning' cssClasses='arrow-bottom header-info text-yellow'/>
                                :
                                // <InfoTooltip infoText={lastUpdateInfoTooltip} icon='info' cssClasses='arrow-bottom header-info'/>
                                ''
                                } */}
                            <SaveButton 
                                payload={this.payload}
                                buttonText='Publish changes'
                                cssClasses={`${this.context.user && this.context.user.emailVerified === false ? "disabled" : ""} primary-button blue-button medium-button`}
                                disabled={`${this.context.user && this.context.user.emailVerified === false ? "true" : "false"}`}
                                dataToProcess={{variant: 'live', property:'questions'}} 
                                db="content"
                                docRef={this.context.contentRefId} />
                        </div>
                    </div>
                </div>
                <div className="mt-80 mb-80">
                    <div className="large-container">
                        <QuestionsCard title='Questions & Answers' isOpen='true' updatePayloadData={this.updatePayloadData} payload={this.payload} />
                    </div>
                </div>
            </div>
            : ''
        );
    }
}

QuestionsEditor.contextType = DataContext;
export default QuestionsEditor;
