import React from "react";
import { DataContext } from '../../../../Containers/DataProvider';
import DOMPurify from 'dompurify';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
// import { getFirestore } from 'firebase/firestore';
import Notification from '../../../Dashboard/Components/Alert';
import { updateDoc, doc } from 'firebase/firestore';
import { db, app } from '../../../../firebase';
import noAvatar from '../../../../assets/images/chat-no-avatar.png'

class ChatSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            error: '',
        }
        this.payload = {};

    }


    componentDidMount() {
        if (this.context.account.widgetSettings) {
            this.setState({
                widgetSettings: JSON.parse(JSON.stringify(this.context.account.widgetSettings)),
            }, () => {
                this.props.updatePayloadData('chatSection', this.state.widgetSettings.draft.chatSection);
            })
        }
    }

    updatePayload = (key, value) => {

        const cleanString = DOMPurify.sanitize(value);

        const newState = Object.assign({}, this.state);
        newState.widgetSettings.draft.chatSection[key] = cleanString;
        this.setState(newState);
        this.props.updatePayloadData('chatSection', this.state.widgetSettings.draft.chatSection);

    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png']; // Allowed file types
        const maxSize = 5 * 1024 * 1024; // Maximum file size in bytes (5MB)

        // Perform validation on the selected file
        if (file) {
            if (!allowedTypes.includes(file.type)) {
                this.setState({ error: 'Invalid file type. Please select a JPEG or PNG image.', selectedFile: null });
                return;
            }

            if (file.size > maxSize) {
                this.setState({ error: 'File size exceeds the limit. Please select a file smaller than 5MB.', selectedFile: null });
                return;
            }

            this.setState({ selectedFile: file, error: '' }, () => {
                this.handleUpload();
            });
        }
    };

    handleUpload = () => {
        const { selectedFile } = this.state;

        if (selectedFile) {
            const storage = getStorage(app);
            const fileRef = ref(storage, `images/${this.context.accountsRefId}/${selectedFile.name}`);

            const uploadTask = uploadBytesResumable(fileRef, selectedFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ uploading: true });
                },
                (error) => {
                    this.setState({ error: 'Error uploading image', selectedFile: null });
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        this.updatePayload('avatarUrl', downloadURL);

                        const widgetSettingsObj = {
                            widgetSettings: {}
                        }
                        // const widgetSettingsObj = Object.assign({}, this.state.widgetSettings);

                        widgetSettingsObj.widgetSettings = this.state.widgetSettings;


                        if (!widgetSettingsObj) {
                            return
                        }
                        widgetSettingsObj.widgetSettings.draft.chatSection.avatarUrl = downloadURL;

                        console.log(widgetSettingsObj.widgetSettings.draft.chatSection.avatarUrl);

                        this.props.updatePayloadData('chatSection', widgetSettingsObj.widgetSettings.draft.chatSection);

                        const currentDocRef = doc(db, 'accounts', this.context.accountsRefId);
                        updateDoc(currentDocRef, widgetSettingsObj).then(() => {

                            this.context.getAccountsTable();

                            Notification.updateNotification({ type: 'success', title: 'Success', message: 'Your data have been updated' });

                            this.setState({ widgetSettings: widgetSettingsObj.widgetSettings });

                        });

                    });
                }
            );
        }
    };

    handleRemove = () => {

        const storage = getStorage(app);
        const encodedUrl = this.state.widgetSettings.draft.chatSection.avatarUrl.split('/o/')[1].split('?')[0];
        const decodedUrl = decodeURIComponent(encodedUrl);
        const fileRef = ref(storage, decodedUrl);

        // Delete the file from storage
        deleteObject(fileRef).then(() => {
            this.updatePayload('avatarUrl', '');

            const widgetSettingsObj = {
                widgetSettings: {}
            }

            widgetSettingsObj.widgetSettings = this.state.widgetSettings;


            if (!widgetSettingsObj) {
                return
            }
            widgetSettingsObj.widgetSettings.draft.chatSection.avatarUrl = '';


            this.props.updatePayloadData('chatSection', widgetSettingsObj.widgetSettings.draft.chatSection);

            const currentDocRef = doc(db, 'accounts', this.context.accountsRefId);
            updateDoc(currentDocRef, widgetSettingsObj).then(() => {

                this.context.getAccountsTable();

                Notification.updateNotification({ type: 'success', title: 'Success', message: 'Your data have been updated' });

                this.setState({ widgetSettings: widgetSettingsObj.widgetSettings });

            });

        }).catch((error) => {
            console.error('Error deleting file:', error);
        });
    };


    render() {
        return (
            this.state.widgetSettings ?
                <>
                    <div className='faq-form'>
                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Your company name</label>
                                <input type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.companyName || ''} onChange={(e) => this.updatePayload('companyName', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className={`fieldset avatar-input ${this.state.uploading ? 'loading' : ''}`}>
                                <label>Avatar - Upload only JPG or PNG files of maximum 5 MB.</label>
                                <div>
                                    <div
                                        className="avatar"
                                        style={{
                                            backgroundImage: `url(${this.state.widgetSettings.draft.chatSection.avatarUrl || noAvatar})`,
                                        }}
                                    >
                                        {
                                            this.state.uploading &&
                                            <div className="loader" />
                                        }
                                    </div>
                                    <label htmlFor="avatar-upload" className={`secondary-button blue-button medium-button ${this.state.uploading ? 'disabled' : ''}`}>
                                        <span>
                                            {this.state.widgetSettings.draft.chatSection.avatarUrl ?
                                                'Edit' : 'Upload'}</span>
                                    </label>
                                    {this.state.widgetSettings.draft.chatSection.avatarUrl &&
                                        <span className={`link ${this.state.uploading ? 'disabled' : ''}`} onClick={() => { this.handleRemove() }}>
                                            <span>Remove</span>
                                        </span>
                                    }
                                    <input type="file" accept="image/jpeg, image/png" id="avatar-upload" onChange={(e) => this.handleFileChange(e)} />
                                </div>
                                {this.state.error && <div className="error">{this.state.error}</div>}
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Assistant name</label>
                                <input type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.assistantName || ''} onChange={(e) => this.updatePayload('assistantName', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Welcome message</label>
                                <textarea type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.welcomeMessage || ''} onChange={(e) => this.updatePayload('welcomeMessage', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Ask for email prompt</label>
                                <textarea type='text' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.askForTheEmailPrompt || ''} onChange={(e) => this.updatePayload('askForTheEmailPrompt', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Where can we send the transcript?</label>
                                <input type='email' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.transcriptEmail || ''} onChange={(e) => this.updatePayload('transcriptEmail', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset input-toggle'>
                                {/* <label>Send the transcript to the user?</label> */}
                                <div className="input">
                                    <p>Send the transcript to the user?</p>
                                    <div className={`switcher ${this.state.widgetSettings.draft.chatSection.sendTranscriptToTheUser ? 'on' : 'off'}`}  onClick={() => this.updatePayload('sendTranscriptToTheUser', !this.state.widgetSettings.draft.chatSection.sendTranscriptToTheUser)}>
                                        <div className='control'></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='one-column'>
                            <div className='fieldset'>
                                <label>Email confrimation message</label>
                                <input type='email' className='input' defaultValue={this.state.widgetSettings.draft.chatSection.emailConfirmationMessage || ''} onChange={(e) => this.updatePayload('emailConfirmationMessage', e.currentTarget.value)}
                                />
                            </div>
                        </div>

                    </div>
                </>
                : ''
        );
    }

}

ChatSettings.contextType = DataContext;
export default ChatSettings;

