import React from "react";
import { Link } from "react-router-dom";
import { DataContext } from '../../../Containers/DataProvider';

class Navigation extends React.Component {
    constructor() {
        super();
        this.state = {location: window.location.pathname};
    }

    componentDidMount() {
        this.setState({ location: window.location.pathname });
    }

    updateLocation = (cuurentLocation) => {
        this.setState({ location: cuurentLocation });
    }

    render() {
        return (
            <div className="navigation">
                {/* <Link to="/dashboard/my-account/account-details" onClick={() => this.updateLocation('/dashboard/my-account')}>
                    <div className={`nav-item ${this.state.location.includes('/dashboard/my-account') ? 'active' : ''}`}>
                        <i className="icon-account"></i>
                        <span>My Account</span>
                    </div>
                </Link> */}
                <Link to="/dashboard/widget-settings/home-screen"  onClick={() => this.updateLocation('/dashboard/widget-settings')}>
                    <div className={`nav-item ${this.state.location.includes('/dashboard/widget-settings') ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            palette
                        </span>
                        <span>Widget settings</span>
                    </div>
                </Link>
                <Link to="/dashboard/categories"  onClick={() => this.updateLocation('/dashboard/categories')}>
                    <div className={`nav-item ${this.state.location === '/dashboard/categories' ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            category
                        </span>
                        <span>Categories</span>
                    </div>
                </Link>
                <Link to="/dashboard/questions"  onClick={() => this.updateLocation('/dashboard/questions')}>
                    <div className={`nav-item ${this.state.location === '/dashboard/questions' ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            help
                        </span>
                        <span>Questions</span>
                    </div>
                </Link>
                <Link to="/dashboard/conversations"  onClick={() => this.updateLocation('/dashboard/conversations')}>
                    <div className={`nav-item ${this.state.location.startsWith('/dashboard/conversations') ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            forum
                        </span>
                        <span>Conversations</span>
                    </div>
                </Link>
                <Link to="/dashboard/install"  onClick={() => this.updateLocation('/dashboard/install')}>
                    <div className={`nav-item ${this.state.location === '/dashboard/install' ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            downloading
                        </span>
                        <span>Install widget</span>
                    </div>
                </Link>
                {/* <Link to="/dashboard/testing-chat-gpt"  onClick={() => this.updateLocation('/dashboard/testing-chat-gpt')}>
                    <div className={`nav-item ${this.state.location === '/dashboard/testing-chat-gpt' ? 'active' : ''}`}>
                        <span className="material-icons icon">
                            face
                        </span>
                        <span>ai-chat-bot</span>
                    </div>
                </Link> */}
                {/* <a href="https://motherfaqr.com/request-feature" target="_blank" rel="noopener noreferrer">
                    <div className="nav-item">
                        <span className="material-icons icon">
                            add_circle
                        </span>
                        <span>Feature request</span>
                    </div>
                </a> */}
                {/* <Link to="/dashboard/content-editor" onClick={() => this.updateLocation('/dashboard/content-editor')}>
                    <div className={`nav-item ${this.state.location === '/dashboard/content-editor' ? 'active' : ''}`}>
                        <i className="icon-faqs"></i>
                        <span>Content Editor</span>
                    </div>
                </Link> */}
                {/* <div className="nav-item">
                    <i className="icon-support"></i>
                    <span>Support</span>
                </div>
                <div className="nav-item">
                    <i className="icon-info"></i>
                    <span>About this app</span>
                </div> */}
            </div>
        )
    }
}

Navigation.contextType = DataContext;
export default Navigation;
