import React from "react";
import DataProvider from '../../Containers/index';
import Dashboard from './Dashboard';

const DashboardWrapper = () => {
    return (
        <DataProvider>
            <Dashboard />
        </DataProvider>
    )
}

export default DashboardWrapper;