import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
// import RichText from '../../Dashboard/Components/RichText';
// import CategoriesDropdown from "./CategoriesDropdown";
import Modal from '../../../hoc/Modal';
import SaveButton from '../../Dashboard/Components/SaveButton';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import DeleteCategory from './DeleteCategory';
import Notification from '../../Dashboard/Components/Alert';
import DOMPurify from 'dompurify';

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.newCategory = {
            name: '',
            description: '',
            id: (Math.random() + 1).toString(36).substring(2),
        };
    }

    componentDidMount() {
        if (this.context.categories) {
            this.setState({
                categories: JSON.parse(JSON.stringify(this.context.categories)),
            }, () => {
                this.props.updatePayloadData('categories', this.state.categories);
            })
        }
    }

    createNewCategory = (key, value, id) => {

        const cleanString = DOMPurify.sanitize(value);

        this.newCategory[key] = cleanString;

        const newState = Object.assign({}, this.state);
        const categories = newState.categories.draft.category;

        const categoryIndex = categories.findIndex(q => q.id === id);

        if (categoryIndex > -1) {

            newState.categories.draft.category[categoryIndex][key] = cleanString;

        } else {

            newState.categories.draft.category.push(this.newCategory);

        }

        this.props.updatePayloadData('categories', newState.categories);

    }

    buildCategoriesHTML = () => {

        const DeleteModal = Modal('<span class="material-icons delete-category">delete</span>', DeleteCategory, 'size-m');
        const EditModal = Modal('<span class="material-icons delete-category">edit</span>', this.AddEditCategoryHTML, 'size-l');

        const categoriesHtml = (
            <>
                {this.state.categories.draft.category.map((category, index) =>
                    <div className="faq-form questions-form" key={category.id}>
                        <div className="">
                            <div className="fieldset">
                                <div className="input">
                                    <span>{category.name || ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <EditModal category={category} addNewEntry={false}/>
                            <DeleteModal category={category} index={index} deleteCategory={this.deleteCategory} isLoading={this.state.deleteButtonIsLoading} />
                        </div>
                    </div>
                )}
            </>
        );

        return categoriesHtml;

    }

    AddEditCategoryHTML = (props) => {

        let entry;
        if (props.addNewEntry) {
            entry = this.newCategory;
        } else {
            entry = props.category;
        }

        const CategoryHtml = (
            <div>
                <div className="faq-form no-margin">
                    <div className="one-column">
                        <div className="fieldset">
                            <label>Category</label>
                            <input type="text" className="input" onChange={(e) => this.createNewCategory('name', e.currentTarget.value, entry.id)} defaultValue={props.category.name || ''} />
                        </div>
                    </div>

                    <div className="one-column">
                        <div className="fieldset">
                            <label>Description</label>
                            <input type="text" className="input" onChange={(e) => this.createNewCategory('description', e.currentTarget.value, entry.id)} defaultValue={props.category.description || ''} />
                        </div>
                    </div>

                </div>
                <div className="buttons">
                    <div className="secondary-button blue-button medium-button" onClick={() => { props.closeModal() }}>
                        <span>Cancel</span>
                    </div>

                    <SaveButton 
                        payload={this.props.payload}
                        buttonText='Save'
                        cssClasses="primary-button blue-button medium-button"
                        dataToProcess={{variant: 'draft', property:'categories'}} 
                        db="content"
                        docRef={this.context.contentRefId} />

                </div>
            </div>
        );

        return CategoryHtml;

    }


    DeleteCategoryHTML = (props) => {

        let isLoading = false;
        const CategoryHtml = (
            <>
                <span className="material-icons text-blue modal-icon">delete</span>
                <h2 className="title">Deleting "<span className="text-blue">{props.category.category}</span>" category?</h2>
                <p className="subtitle"></p>
                <div className="buttons">
                    <div className="secondary-button blue-button medium-button" onClick={() => { props.closeModal() }}>
                        <span>Cancel</span>
                    </div>
                    <div className={`primary-button blue-button medium-button ${isLoading === true ? "loading disabled" : ""}`} onClick={() => { props.deleteCategory(props.index); isLoading = true;}}>
                        <span>Delete</span>
                    </div>

                </div>
            </>
        );

        return CategoryHtml;

    }

    deleteCategory = (index) => {

        const newState = Object.assign({}, this.state);
        if (!newState) {
            return
        }
        newState.categories.draft.category.splice(index, 1);
        newState.categories.draft.lastUpdate = new Date().toLocaleString();
        this.props.updatePayloadData('categories', newState.categories);

        const currentDocRef = doc(db, 'content', this.context.contentRefId);
        updateDoc(currentDocRef, newState).then(() => {

            document.body.classList.remove('modal-open', 'overflow-hidden');
            this.context.getContentTable();

            Notification.updateNotification({type: 'success',title: 'Success',message: 'Your data have been updated'}); 

        });

    }

    render() {

        const AddNewModal = Modal('<div class=" secondary-button blue-button medium-button "><span>Add new  category</span></div>', this.AddEditCategoryHTML, 'size-l');

        return (
            this.state && this.state.categories ?
                <>
                    {
                        this.state.categories.draft.category.length > 0
                            ?
                            this.buildCategoriesHTML()
                            :
                            <div className="no-item-added">
                                <p>
                                    <span className="material-icons text-blue">add_circle</span>
                                    <span>No categories added yet. Add your first category.</span>
                                </p>
                            </div>
                    }
                    {
                        this.state.categories.draft.category.length < this.context.activeSubscription.categories
                        ?
                        <div className="text-right mt-30">
                            <AddNewModal category={this.newCategory} addNewEntry={true} />
                        </div>
                        :
                        <div className="limit-reached-info">
                            <span className="material-icons">warning</span>
                            <p>You've reached the limit for categories. New plans will be added soon. </p>
                        </div>
                    }
                </>
            : ''
        );
    }

}

Categories.contextType = DataContext;
export default Categories;