import React from "react";
import { SketchPicker } from 'react-color';
import InfoTooltip from './InfoTooltip';

class ColorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    toggleColorPicker = () => {
        this.setState({
            colorPickerIsVisible: !this.state.colorPickerIsVisible
        })
    }

    componentDidMount() {
        this.updateColorForInput(this.props.color);
    }

    updateColorForInput(color) {
        this.setState({
            color: color
        })
    }

    validateColorHex = (color) => {

        const hexRegex = /^#[0-9a-f]{3,6}$/i;
        if (hexRegex.test(color)) {
            this.setState({ fieldError: false });
            this.props.UpdateColor(this.props.propToUpdate, color);
        } else {
            this.setState({ fieldError: true });
        }

    }


    render() {
        return (
            <div className={`fieldset color-field w50 ${this.props.wrapperCssClasses}`}>
                <label>{this.props.labelText ? this.props.labelText : ''} {this.props.infoTooltip ? <InfoTooltip infoText={this.props.infoTooltip} /> : ''}</label>
                <div>
                    <div className="color-view form-input"
                        style={{ 'backgroundColor': this.props.color }}
                        onClick={this.toggleColorPicker}
                    ></div>
                    <input type='text' className={`input ${this.state.fieldError ? "has-error" : ""
                        }`} defaultValue={this.state.color || ''}
                        onChange={(e) => (
                            this.validateColorHex(e.currentTarget.value))
                        } />
                    {this.state.fieldError ? (
                        <div className="error">
                            Needs to be hex
                        </div>
                    ) : (
                            ""
                        )}
                    {
                        this.state.colorPickerIsVisible ?
                            <>
                                <div style={{ 'position': 'fixed', 'inset': '0px', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%', 'zIndex': '10' }} onClick={this.toggleColorPicker}></div>
                                <SketchPicker
                                    color={this.props.color || ''}
                                    onChange={
                                        (color) => { this.validateColorHex(color.hex); this.updateColorForInput(color.hex) }
                                    }
                                />
                            </>
                            : ''
                    }
                </div>
            </div>
        );
    }

}

export default ColorField;