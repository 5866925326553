import React from "react";
import Navigation from './Components/Navigation';
import { Switch, Route } from "react-router-dom";
import Header from './Components/Header';
import InfoBox from './Components/InfoBox';
import WidgetSettings from '../Widget Settings/WidgetSettings';
import CategoriesEditor from '../Categories/CategoriesEditor';
import QuestionsEditor from '../Questions/QuestionsEditor';
import InstallWidgetWrapper from '../Install Widget/InstallWidgetWrapper';
import { sendEmailVerification } from "firebase/auth";
import { DataContext } from '../../Containers/DataProvider';
import Notification from './Components/Alert';
import Conversations from '../Conversations/Conversations';

class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            sendEmailVerificationIsLoading: false,
        }
    }
    resendVerificationEmail = () => {

        this.setState({
            sendEmailVerificationIsLoading: true,
        });

        sendEmailVerification(this.context.user).then(() => {

            this.setState({
                sendEmailVerificationIsLoading: false,
            });
            // this.context.updateAlerts({
            //     type: 'success',
            //     title: 'Success',
            //     message: 'Email Verification Sent!'
            // });

        }).catch(() => {
            this.setState({
                sendEmailVerificationIsLoading: false,
            });
            // this.context.updateAlerts({
            //     type: 'error',
            //     title: 'Something went wrong',
            //     message: 'Try again in a few minutes.'
            // });
        })

    };



    render() {
        return (
            <div className='content-wrapper'>
                <Header />
                {
                    this.context.user && this.context.user.emailVerified === false ?
                        <InfoBox
                            infoText='<h2>Email not verified</h2><p>To publish your widget, first you need to confim your email.</p>'
                            icon='notification_important'
                            iconCssClasses='text-blue'
                            showButton="true"
                            buttonText="Resend verification email"
                            buttonCssClasses={`primary-button blue-button medium-button ${this.state.sendEmailVerificationIsLoading ? 'loading disabled' : ''}`}
                            boxCssClasses=""
                            buttonAction={this.resendVerificationEmail}
                        />
                        : ''
                }
                <div className='main-content'>
                    <Navigation />
                    <div className='view'>
                        {
                            <Switch>
                                <Route path="/dashboard/widget-settings">
                                    <WidgetSettings />
                                </Route>
                                <Route path="/dashboard/categories">
                                    <CategoriesEditor />
                                </Route>
                                <Route path="/dashboard/questions">
                                    <QuestionsEditor />
                                </Route>
                                <Route path="/dashboard/install">
                                    <InstallWidgetWrapper />
                                </Route>
                                <Route path="/dashboard/conversations">
                                    <Conversations />
                                </Route>
                            </Switch>
                        }
                    </div>
                </div>
                <Notification />
                <p className="copyrights">© {new Date().getFullYear()} Answers Automate</p>
            </div>
        );
    }
}

Dashboard.contextType = DataContext;
export default Dashboard;