import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";

const Tabs = () => {
  let { path } = useRouteMatch();
  return (
      <div className="auth-tabs">
          <Link to='/auth/login' className={`tab ${path === '/auth/login' ? 'active' : ''}`}>Login</Link>
          <Link to='/auth/register' className={`tab ${path === '/auth/register' ? 'active' : ''}`}>Register</Link>
      </div>
  );
}

export default Tabs;
