import React from "react";
import { Link } from "react-router-dom";
import Tabs from "./Tabs";
import emailSent from "../../assets/images/Auth/email-sent.svg";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import DOMPurify from 'dompurify';


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: false,
      domain: "",
      domainError: false,
      requiredFieldErrorMessage: "Please complete this required field",
      email: "",
      emailError: false,
      emailErrorMessage: "Invalid email address",
      apiEmailErrors: "",
      password: "",
      passwordError: false,
      passwordErrorMessage: "The password should have at least 8 characters",
      apiPasswordErrors: "",
      showPassword: false,
      isSubmitting: false,
      showSuccesScreen: false,
    };
  }

  validateEmail = event => {

    const cleanString = DOMPurify.sanitize(event.target.value);
    this.setState({ email: cleanString });
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (cleanString.length < 1) {
      this.setState({ emailError: false });
      return true;
    }

    if (emailRegex.test(cleanString)) {
      this.setState({ emailError: false });
      return true;
    } else {
      this.setState({ emailError: true });
      return false;
    }
  };

  validatePassword = event => {

    const cleanString = DOMPurify.sanitize(event.target.value);
    this.setState({ password: cleanString });

    if (cleanString.length < 1) {
      this.setState({ passwordError: false });
      return true;
    }
    if (cleanString.length > 7) {
      this.setState({ passwordError: false });
      return true;
    } else {
      this.setState({ passwordError: true });
      return false;
    }
  };

  validateRequired = (event, fieldName) => {
    const key = fieldName;
    const val = event.target.value;
    const obj = {};
    obj[key] = val;

    if (event.target.value.length > 0) {
      obj[`${key}Error`] = false;
      this.setState(obj);
      return true;
    } else {
      obj[`${key}Error`] = true;
      this.setState(obj);
      return false;
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.email.length === 0) {
      this.setState({ emailError: true });
    }

    if (this.state.password.length === 0 || this.state.password.length < 7) {
      this.setState({ passwordError: true });
    }

    // if (this.state.name.length === 0) {
    //   this.setState({ nameError: true });
    // }
    // if (this.state.domain.length === 0) {
    //   this.setState({ domainError: true });
    // }

    if (
      this.state.email.length > 0 &&
      this.state.emailError === false &&
      this.state.password.length > 7 &&
      this.state.passwordError === false
    ) {
      this.setState({ isSubmitting: true });
      
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then(() => {

          sendEmailVerification(auth.currentUser);

        })
        .catch((error) => {
          const errorCode = error.code;

          if (errorCode === 'auth/email-already-in-use') {
            
            this.setState({ apiEmailErrors: 'Email already in-use.' });

          }

          this.setState({ isSubmitting: false });
      });
    }
  };

  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      <div className='animated-wrapper'>
        <Tabs />
        <div className="form-wrapper">
          {!this.state.showSuccesScreen ?
            (<form className="faq-form" onSubmit={this.handleSubmit}>
              {this.state.apiEmailErrors.length > 0 ? (
                <p className="general-form-error text-center">
                  
                  {this.state.apiEmailErrors} Go to{" "}
                  <Link to="/auth/login">login</Link>.
              </p>
              ) : (
                  ""
                )}

              {/* <div className="fieldset">
                <label>Name</label>
                <input
                  type="text"
                  className={`input ${this.state.nameError ? "has-error" : ""}`}
                  placeholder="John Doe"
                  onChange={event => this.validateRequired(event, "name")}
                />
                {this.state.nameError ? (
                  <div className="error">
                    
                    {this.state.requiredFieldErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
              </div> */}
              <div className="fieldset">
                <label>Email address</label>
                <input
                  type="email"
                  className={`input ${
                    this.state.emailError ? "has-error" : ""
                    }`}
                  placeholder="name@company.com"
                  onChange={this.validateEmail}
                />
                {this.state.emailError ? (
                  <div className="error">
                    
                    {this.state.emailErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
              </div>
              <div className="fieldset">
                <label>Password</label>
                <input
                  type={`${this.state.showPassword ? "text" : "password"}`}
                  className={`input ${
                    this.state.passwordError ? "has-error" : ""
                    }`}
                  placeholder="********"
                  onChange={this.validatePassword}
                />
                {this.state.passwordError ? (
                  <div className="error">
                    
                    {this.state.passwordErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
              <div className='password-action material-icons' onClick={this.showPassword}>
                  {this.state.showPassword ? 'visibility' : 'visibility_off'}
                </div>
              </div>
              {/* <div className="fieldset">
                <label>Domain</label>
                <input
                  type="text"
                  placeholder="Google"
                  onChange={event => this.validateRequired(event, "domain")}
                  className={`input ${
                    this.state.domainError ? "has-error" : ""
                    }`}
                />
                {this.state.domainError ? (
                  <div className="error">
                    
                    {this.state.requiredFieldErrorMessage}
                  </div>
                ) : (
                    ""
                  )}
              </div> */}
              <button
                type="submit"
                className={`primary-button blue-button ${this.state.isSubmitting ? 'loading' : ''}`}
                disabled={this.state.isSubmitting}
              ><span>Register</span></button>
            </form>)
            :
            (
              <div className='one-more-step'>
                <div className="form-title">
                  <h2>Just one more step...</h2>
                  <p>
                    We just need to verify your email address to complete your Answers Automate signup.
                </p>
                </div>
                <img src={emailSent} alt="Email on the way" />
              </div>
            )
          }

        </div>
      </div>
    );
  }
}

export default Register;
