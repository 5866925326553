
function getParams() {

    var params = {};
    if (window.location.search) {

        var parts = window.location.search.substring(1).split('&');
        for (var i = 0; i < parts.length; i++) {
            var nv = parts[i].split('=');
            if (!nv[0]) continue;
            params[nv[0]] = nv[1] || true;
        }

    }
    return params;

}

function saveParams(params) {

  sessionStorage.setItem('extraParams', JSON.stringify(params));

}

function getSavedParams(key) {

  const params = sessionStorage.getItem(key)
  return JSON.parse(params);

}

function getCookie(cname) {

    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";

}

export { getParams, getCookie, saveParams, getSavedParams };