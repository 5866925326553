import React from "react";
import { Link } from "react-router-dom";

class DashboardTabs extends React.Component {
    constructor() {
        super();
        this.state = { location: window.location.pathname };
    }

    componentDidMount() {
        this.setState({ location: window.location.pathname });
    }

    updateLocation = (cuurentLocation) => {
        this.setState({ location: cuurentLocation });
    }

    render() {
        return (
            <div className={this.props.cssClasses}>
                <div className='dashboard-tabs'>
                    <div>
                        {this.props.links.map((link) =>
                            <Link to={link.url} key={link.url}
                                className={`${this.state.location === link.url ? 'active' : ''}`}
                                onClick={() => { this.updateLocation(link.url) }}>
                                {link.title}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }

}

export default DashboardTabs;