import React from "react";
import { DataContext } from '../../../Containers/DataProvider';
import WithCard from '../../../hoc/WithCard';
import FAQs from './Home Screen/FAQs';
import Chat from './Home Screen/Chat';

class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.payload = {};
    }

    render() {

        const FAQsCard = WithCard(FAQs);
        const ChatCard = WithCard(Chat);
        this.payload = this.props.payload;

        return (
            <div>
                <div className="mt-80 mb-80">
                    <div className='large-container'>
                        <FAQsCard updatePayloadData={this.props.updatePayloadData} payload={this.payload} title='FAQs' isOpen={true} />
                        <ChatCard updatePayloadData={this.props.updatePayloadData} payload={this.payload} title='Chat' isOpen={true} />
                    </div>
                </div>
            </div>
        );
    }
}

HomeScreen.contextType = DataContext;
export default HomeScreen;
