import React from "react";
import { Link } from "react-router-dom";
import emailSent from "../../assets/images/Auth/email-sent.svg";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import DOMPurify from 'dompurify';

class Forgot extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailError: false,
      emailErrorMessage: "Invalid email address",
      apiEmailErrors: "",
      apiEmailSucces: "",
      isSubmitting: false,
      success: false,
    };
  }

  validateEmail = event => {

    const cleanString = DOMPurify.sanitize(event.target.value);
    this.setState({ email: cleanString });
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (cleanString.length < 1) {
      this.setState({ emailError: false });
      return true;
    }
    if (emailRegex.test(cleanString)) {
      this.setState({ emailError: false });
      return true;
    } else {
      this.setState({ emailError: true });
      return false;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.email.length === 0) {
      this.setState({ emailError: true });
    }

    if (this.state.email.length > 0 && !this.state.emailError) {
      this.setState({ isSubmitting: true });
      
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.state.email)
      .then(() => {
        this.setState({ success: true, isSubmitting: false });
      })
      .catch((error) => {
        const errorCode = error.code;

        if (errorCode === 'auth/user-not-found') {
          this.setState({ apiEmailErrors: 'Email not found.' });
        } 

        this.setState({ isSubmitting: false });
      
      });
    }
  };

  render() {
    return (
      <div className='animated-wrapper'>
        <div className="form">
          <div className="form-wrapper full-radius">
            {!this.state.success ?
              (
                <>
                  <div className="form-title">
                    <h2>Recover your password</h2>
                    <p>
                      Please enter the email address you’d like your password reset
                      information sent to.</p>
                  </div>
                  <form className="faq-form" onSubmit={this.handleSubmit}>
                    <div className="fieldset">
                      <label>Email address</label>
                      <input type="email"
                        className={`input ${
                          this.state.emailError ? "has-error" : ""
                          }`}
                        placeholder="name@company.com"
                        onChange={this.validateEmail} />
                      {this.state.emailError ? (
                        <div className="error">
                          {this.state.emailErrorMessage}
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {this.state.apiEmailErrors.length > 0 ? (
                      <p className="general-form-error text-center">
                        
                        {this.state.apiEmailErrors} Go to <Link to="/auth/register">register</Link>.
                </p>
                    ) : (
                        ""
                      )}

                    {this.state.apiEmailSucces.length > 0 ? (
                      <p className="general-form-error text-center">
                        
                        {this.state.apiEmailSucces}
                      </p>
                    ) : (
                        ""
                      )}
                    <button
                      type="submit"
                      className={`primary-button blue-button ${this.state.isSubmitting ? 'loading' : ''}`}
                      disabled={this.state.isSubmitting}
                    ><span>Recover</span></button>
                  </form>
                  <Link to="/auth/login" className="back hover-effect">
                    <span className="material-icons">arrow_back</span>
                    Back to login
                  </Link>
                </>
              ) : ''
            }
            {this.state.success ?
              (
                <>
                  <div className="form-title">
                    <h2>You've got a new link</h2>
                    <p>Check your email address.</p>
                  </div>
                  <img src={emailSent} alt='Email sent' />
                </>
              )
              : ''}
          </div>
        </div>
      </div>
    );
  }
};

export default Forgot;
